<template>
  <div class="quotes" id="quotes">
    <main-menu></main-menu>
    <v-app id="inspire" style="background-color:#f3f3f3" >
      <v-card class="my-5 mx-auto" outlined max-width="100%" max-height="100%">
        <v-container dense >
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row no-gutters>
              <v-col class=" " cols="10">
                <v-row class="py-0">
                  <v-col class="port py-0" cols="4">
                    <v-select
                      v-model="type"
                      :items="types"
                      dense
                      label="Type"
                    ></v-select>
                  </v-col>
                  <v-col class="qty py-0" cols="2">
                    <v-autocomplete
                      v-model="q20"
                      :items="quantity"
                      label="20 'GP"
                      auto-select-first
                      return-object
                      dense
                      :error-messages="qtyMessage()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="qty py-0" cols="2">
                    <v-autocomplete
                      v-model="q40"
                      :items="quantity"
                      label="40 'GP"
                      auto-select-first
                      return-object
                      dense
                      :error-messages="qtyMessage()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col class="qty py-0" cols="2">
                    <v-autocomplete
                      v-model="q40hq"
                      :items="quantity"
                      label="40 'HQ"
                      auto-select-first
                      return-object
                      dense
                      :error-messages="qtyMessage()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col class="qty py-0" cols="2">
                    <v-autocomplete
                      v-model="q45hq"
                      :items="quantity"
                      label="45 'HQ"
                      auto-select-first
                      return-object
                      dense
                      :error-messages="qtyMessage()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="py-0 mx-auto">
                  <v-alert type="error" dense v-model="alert">{{alert_message}}</v-alert>
                </v-row>
                <v-row class="py-0">
                  <v-col class="port py-0" cols="4">
                    <select-port
                      v-model="origin"
                      item-text="name"
                      item-value="code"
                      label="From"
                      required
                      :rules="portRules"
                      hide-no-data
                      searchType="SEA"
                      prepend-icon="mdi-database-search"
                      :error-messages="portFromMessage()"
                    ></select-port>
                  </v-col>
                  <v-col class="port py-0" cols="4">
                    <select-port
                      v-model="destination"
                      item-text="name"
                      item-value="code"
                      label="To"
                      required
                      :rules="portRules"
                      hide-no-data
                      searchType="SEA"
                      prepend-icon="mdi-database-search"
                      :error-messages="portToMessage()"
                    ></select-port>
                  </v-col>
                  <v-col class="date py-0" cols="4">
                    <v-menu
                      ref="etd_menu"
                      v-model="etd_menu"
                      :close-on-content-click="false"
                      :return-value.sync="earliestEtd"
                      transition="scale-transition"
                      offset-y
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="earliestEtd"
                          label="Cargo Ready Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          class="date"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="earliestEtd"
                        :min="minDate"
                        :max="maxDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="earliestEtd = null">Clear</v-btn>
                        <v-btn color="primary" @click="etd_menu = false">Cancel</v-btn>
                        <v-btn color="primary" @click="$refs.etd_menu.save(earliestEtd)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="mx-auto px-auto">
                <v-card-actions>
                  
                <v-btn color="primary" dense @click="search_quotes">Search</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
      <v-container class="mx-auto">
          
        <v-row >
          <v-col cols=2>
              
            <v-form ref="form" v-model="filter">
            <v-card class="mx-auto px-0" outlined>
              <v-card-title>Filters</v-card-title>
              <v-card flat class="pa-0">
              
              <v-card-subtitle class="subtitle ">
                <v-row no-gutters >
                  <v-col>
                    Carrier 
                    <v-icon @click="checkAll" small>mdi-check</v-icon>
                    <v-icon @click="uncheckAll" small>mdi-close</v-icon>
                  </v-col>
                  
                </v-row>
              </v-card-subtitle>
              
              <v-row v-for="carrier in carriers" :key="carrier" no-gutters>
                <v-col class="px-5">
                  
                  <input type="checkbox"  @change="filterCarrier()" v-model="checkedCarriers[carrier]" /> {{carrier}}
                </v-col>
              </v-row>
              </v-card>
            </v-card>
            
            </v-form>
          </v-col>

          <v-col class="py-0 px-0 results"  min-width:500px>
            <v-row no-gutters class="py-0 px-0">
                 
                <v-col class="d-flex px-3" cols="3" >
                  <v-select
                      dense
                      v-model="sortingCriteria"
                      :items="sortedBy"
                      label="Sorted By"
                      value="Cheapest"
                      outlined
                      background-color="lightgray"
                      @change="sortingBy"
                  ></v-select>
                </v-col>
                
                <v-col class="d-flex"  cols="3" > 
                  <v-card flat color="transparent" v-show="isShowDiscount">
                    <v-row>
                      <v-col class="d-flex"> 
                        <v-text-field label="Discount Code" dense v-model="discountCode"></v-text-field> <v-btn @click="getDiscount" color="primary" small>Search</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters class="py-0 px-0">
              <v-col v-for="result in results" :key="result.SpaceID" cols="12" class="result py-0">
                <v-card class="card-container py-0">
                  <v-row>
                    <v-col cols="10" class="py-0">


                      <table style="width:100%">
                        <tr>
                          <td cols="2">
                            <v-card-subtitle class="py-0 detail-title">Carrier</v-card-subtitle>
                          </td>
                          <td cols="3">
                            <v-card-subtitle class="py-0 detail-title">ETD</v-card-subtitle>
                          </td>
                          <td cols="2">
                            <v-card-subtitle class="py-0 detail-title">TT</v-card-subtitle>
                          </td>
                          <td cols="3">
                            <v-card-subtitle class="py-0 detail-title">ETA</v-card-subtitle>
                          </td>
                          <td cols="2">
                            <v-card-subtitle class="py-0 detail-title">Vessel Voyage</v-card-subtitle>
                          </td>
                        </tr>
                        <tr no-gutters>
                          
                          <td cols="2" rowspan="2">
                            <v-img contain :aspect-ratio="1" width="150px" height="60px" :src="`${publicPath}images/pr/${result.PrCode}.png`">
                            </v-img>  
                          </td>
                          
                          <td cols="3">
                            <v-card-text class="py-0">{{result.ETD}}</v-card-text>
                          </td>

                          <td cols="2">
                            <v-card-text class="py-0">{{result.TT}} Days</v-card-text>
                          </td>
                          <td cols="3">
                            <v-card-text class="py-0">{{result.ETA}}</v-card-text>
                          </td>
                          <td cols="2">
                            <v-card-text class="py-0">{{result.VslName + " " + result.Voy}}</v-card-text>
                          </td>
                        </tr>
                        <tr no-gutters>
                          <td cols="3">
                            <v-card-text class="py-1">{{result.PolName}}</v-card-text>
                          </td>
                          <td cols="2">&nbsp;</td>
                          <td>
                            <v-card-text class="py-1">{{result.PldName}}</v-card-text>
                          </td>
                          
                          <td>
                            <v-card-text class="py-1 pl-2">{{result.ServiceCode}}</v-card-text>
                          </td>

                        </tr>
                      </table>


                      <v-row no-gutters class="ma-0 pa-0">
                        
                        <v-col cols="2" class="mb-0 pb-0">
                          <v-card-text class="mb-0 pb-0 table-title">Ocean Freight</v-card-text>
                        </v-col>
                        <v-col cols="10" class="py-1">
                          <v-btn color="primary" icon @click="result.isShowDetail=!result.isShowDetail" ><v-icon>mdi-plus-thick</v-icon></v-btn> 
                          <table class="mx-0">
                            <thead class="prx_table_th">
                              <th>20' GP</th>
                              <th>40' GP</th>
                              <th>40' HQ</th>
                              <th>45' HQ</th>
                            </thead>
                            <tbody class="prx_table_td">
                              <td>{{result.GP20F}}</td>
                              <td>{{result.GP40F}}</td>
                              <td>{{result.HQ40F}}</td>
                              <td>{{result.HQ45F}}</td>
                            </tbody>
                          </table>
                          
                          <v-card outlined v-show="result.isShowDetail" class="mr-10">
                            <v-card-title>Service Routing</v-card-title>
                            <v-timeline dense clipped>
                              <v-timeline-item small>
                                <v-chip label color="primary" >{{result.ETD}}<country-flag :country='result.PolCnty' size='normal'/>{{result.PolName.substr(0,result.PolName.indexOf(','))}}</v-chip>
                              </v-timeline-item>
                              
                              <v-timeline-item small v-show="result.ViaAMSCode!=''" hide-dot>
                                <small>{{result.ViaTT}} days</small>
                              </v-timeline-item>

                              <v-timeline-item small v-show="result.ViaAMSCode!=''">
                                <v-chip label color="primary" >{{result.PolETD}}<country-flag :country='result.ViaCnty' size='normal'/>{{result.ViaName.substr(0,result.ViaName.indexOf(','))}}</v-chip>
                              </v-timeline-item>
                              
                              <v-timeline-item small hide-dot>
                                <small>{{result.PodTT}} days</small>
                              </v-timeline-item>

                              <v-timeline-item small >
                                <v-chip label color="primary" >{{result.PodETA}}<country-flag :country='result.PodCnty' size='normal'/>{{result.PodName.substr(0,result.PodName.indexOf(','))}}</v-chip>
                              </v-timeline-item>

                              <v-timeline-item small hide-dot>
                                <small>{{result.PldTT}} days</small>
                              </v-timeline-item>

                              <v-timeline-item small >
                                <v-chip label color="primary" >{{result.ETA}}<country-flag :country='result.PldCnty' size='normal'/>{{result.PldName.substr(0,result.PldName.indexOf(','))}}</v-chip>
                              </v-timeline-item>

                            </v-timeline>
                            <v-card-title>Local Charges</v-card-title>
                            <v-spacer></v-spacer>
                            <v-row no-gutters v-for="LocalCharge in result.LocalCharges" :key="LocalCharge.ChgID">
                              <v-col cols=6 class='mx-5'>
                                  <small>{{LocalCharge.ChgName}}</small>
                              </v-col>
                              <v-col cols=1>
                                  <small>{{LocalCharge.SellCurrCode}}</small>
                              </v-col>
                              <v-col cols=4>
                                  <small>{{LocalCharge.SellUnitPrice}} X {{LocalCharge.NoOfUnit}}</small>
                              </v-col>
                            </v-row>
                            <v-card-title>Remark</v-card-title>
                            <v-card-text>
                              <pre>{{getRemark() }}</pre>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="py-0">
                      <v-row style="height:100%">
                        <v-col cols="2">
                          <v-divider vertical></v-divider>
                        </v-col>
                        <v-col cols="10" class="mx-auto" >
                          <v-card class="mx-auto" flat>
                              
                            
                            <v-row no-gutters>
                              <v-col class="mx-auto">
                                  <pre>{{result.TotalAll}}</pre>
                              </v-col>
                            </v-row>
                            <v-row no-gutters v-show="result.isShowDiscount">
                              <v-col class="mx-auto">
                                <pre>{{discountAll}}</pre>
                              </v-col>
                            </v-row>
                            <v-row no-gutters class="mx-auto pb-0 mb-0">
                              <v-col >
                                <v-btn
                                  color="primary"
                                  class="mx-auto"
                                  :disabled="checkTEU(result.TEU)"
                                  @click="toBookingForm(result)"
                                  width="100%"
                                >Book</v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-snackbar centered :color="message_color" v-model="snackbar">
          {{ this.message }}
          <v-btn text @click="snackbar = false" style="float:right" >
              <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-snackbar>

      <v-dialog v-model="dialog" max-width="500" >
          <v-card>
              <v-card-title class="headline">Notice</v-card-title>
      
              <v-card-text>
                  Oops, the rates you are searching is currently now available, would you like to send an inquiry to HLS?”
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
      
                  <v-btn color="#92D050" text @click="dialog = false" class="btn">
                  No, Thanks
                  </v-btn>
      
                  <v-btn color="#92D050" dark tile @click="sendInquiry" class="btn">
                  Yes
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
  import Vue from "vue";
  import MainMenu from "@/components/Menu";
  import SelectPort from "@/components/Select/SelectPort.vue";
  import CountryFlag from 'vue-country-flag'
  import { SearchPricing, SendInquiry, GetLocalCharges, GetPricing, IsAllowDiscount, GetDiscount } from "@/api/publicServices";
  import { GetPriceDetail } from "@/api/common";

  import backgroundUrl from "@/assets/quotes_background_image.jpg";

  Vue.component("main-menu", { MainMenu });
  Vue.component('country-flag', CountryFlag);
  export default {
    components: { MainMenu, SelectPort: SelectPort },
    mounted () {
        this.loadShowDiscount();
        console.log('isShowDiscount: ' + this.isShowDiscount);
    },
    data: () => ({
      publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
      backgroundStyle: {
        "background-image": `url(${backgroundUrl})`,
        "background-repeat": "no-repeat",
        "background-size": "cover"
      },
      tabs: [],
      filter: null,
      valid: true,
      quantity:getQuantity(),
      types:['FCL', 'LCL', 'AIR'],
      type: 'FCL',
      sortedBy : ['Cheapest', 'Fastest', 'Earliest'],
      q20: null,
      q40: null,
      q40hq: null,
      q45hq: null,
      earliestEtd: new Date().toISOString().substr(0, 10),
      etd_menu: false,
      modal: false,
      menu2: false,
      origin: null,
      destination: null,
      minDate: new Date().toISOString().substr(0, 10),
      landscape: false,
      reactive: false,
      filter_etd_from: null,
      filter_etd_to: null,
      filter_eta_from: null,
      filter_eta_to: null,
      filter_etd_from_menu: false,
      filter_etd_to_menu: false,
      filter_eta_from_menu: false,
      filter_eta_to_menu: false,
      filter_direct: false,
      transshipments: [],
      sortingCriteria: 'Cheapest',
      allResults: [],
      results: [],
      portRules: [v => !!v || "This field is required"],
      lazy: false,
      port_from_message: "",
      port_to_message: "",
      alert_message:"",
      alert:false,
      qty_message: "",
      dialog: false,
      snackbar: false,
      message:"",
      message_color:"primary",
      searchParams: {},
      carriers:[],
      checkedCarriers: {},
      discountCode:"",
      isShowDiscount:false,
      discount:null,
      discountAll:""
    }),
    computed: {
      maxDate() {
        var endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate.toISOString().substr(0, 10);
      },
    },
    methods: {
      getRemark() {
        let remark = "1. The rates offered in this quotation are under Port to Port or Terminal to Terminal service mode;\n\n" +
                      "2. The rates offered in this quotation are applicable for the designated sailing schedule and routing only;\n\n" +
                      "3. The rates offered in this quotation will be effective once HLS confirmed your booking online;\n\n" +
                      "4. HLS is operating under the Terms and Conditions of its Bill of Lading or Sea Waybill (depending on which document will be issued for the shipment) and the HLS Tariff Terms and Conditions effective at time of shipment apply to this quotation.\n\n" +
                      "5. This quotation is subject to space and equipment availability and subject to compliance by you with all applicable cargo weight restrictions and regulations. Notwithstanding the generality of the foregoing, this includes Regulation 2 of Chapter VI of the International Convention for the Safety of Life at Sea (SOLAS), stipulating that only containers for which the correct Verified Gross Mass (VGM) is provided in time may be loaded and shipped. Compliance with this requirement is the sole responsibility of the shipper and no responsibility of whatsoever nature is assumed by us in relation thereto;\n\n" +
                      "6. Free time of equipment usage, free storage, chassis usage are subject to the tariff of the shipping line that offered in the quotation;\n\n" +
                      "7. The cargo weight per container is under the guideline or restriction of the shipping lines that offered in the quotation.\n\n" +
                      "8. HLS only accepts bookings for general cargo (non-hazardous) in this online portal, for D.G. Cargo, Temperature controlled cargo, or any shipment required special equipment, please contact our sales representatives or customer service for further assistance.";
        return remark
      },
      isValidDiscount(result) {
        result.isShowDiscount = false;
        if (this.discountCode != '' && this.discount != null) {
          //if (this.discount.EffectiveDate.substring(0,10) >= result.ETD && result.ETD <= this.discount.ExpiryDate.substring(0,10)) {
            return true;
          //}
        }
        return false;
      },
      async loadShowDiscount() {

        let UserID = localStorage.getItem('userid');
        try {
          const response = await IsAllowDiscount(UserID);
          this.isShowDiscount = response.data.isSuccess;
        } catch (error) {
          console.log(error);
        }

      },
      async getDiscount() {
        this.discount =null;
        let UserID = localStorage.getItem('userid');
        try {
          const response = await GetDiscount(UserID, this.discountCode);

          if (response.data != null) {
            
            
            this.discount = response.data;
            this.showDiscount();
            this.showResult();
          }
        } catch (error) {
          console.log(error);
        }

      },
      showDiscount() {
        this.discountAll = "";
        if (this.discount == null) {
          return;
        }
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2
        });
        this.discountAll = "Discount: \n @" + this.discount.CurrCode + " " + formatter.format(this.discount.Amount) + " X " + this.sumQty() + "\n";    
        
      },
      sumQty() {
        let qty20 = (this.q20 == null || this.q20 == "") ? 0 : this.q20;
        let qty40 = (this.q40 == null || this.q40 == "") ? 0 : this.q40;
        let qty40hq = (this.q40hq == null || this.q40hq == "") ? 0 : this.q40hq;
        let qty45hq = (this.q45hq == null || this.q45hq == "") ? 0 : this.q45hq;
        return qty20 + qty40 + qty40hq + qty45hq;
      },
      async getServiceRouting(SpaceID, result) {
        try {
          const response = await GetPricing(SpaceID);
          result.PolName = response.data.PolName;
          result.PolCnty = response.data.PolCnty;
          result.ViaName = response.data.ViaName;
          result.ViaCnty = response.data.ViaCnty;
          result.PodName = response.data.PodName;
          result.PodCnty = response.data.PodCnty;
          result.PldName = response.data.PldName;
          result.PldCnty = response.data.PldCnty;
        } catch (error) {
          console.log(error);
        }

      },
      async getLocalCharges(SpaceID, result) {
        
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2
        });
        try {
          result.LocalCharges = []; 
          const response = await GetLocalCharges(SpaceID);
          let LocalCharges = response.data;
          if (LocalCharges.length == 0) {
            return;
          }

          let amtMap = {};
          
          for (var index in LocalCharges) {
            let LocalCharge = LocalCharges[index];
            LocalCharge.NoOfUnit = GetPriceDetail(result, LocalCharge).NoOfUnit;
            
            if (LocalCharge.NoOfUnit == null ||LocalCharge.NoOfUnit == "" || LocalCharge.NoOfUnit == 0) {
              continue;
            }
            
            if (!amtMap[LocalCharge.SellCurrCode]) {
              amtMap[LocalCharge.SellCurrCode] = 0;
            }  
            amtMap[LocalCharge.SellCurrCode] += LocalCharge.SellUnitPrice * LocalCharge.NoOfUnit;
            
            LocalCharge.SellUnitPrice = formatter.format(LocalCharge.SellUnitPrice);
            result.LocalCharges.push(LocalCharge);
          }
          amtMap[result.Currency] += result.Total;
          result.TotalAll = "";
          for (let i2 in amtMap) {
           
             result.TotalAll += i2 + " " + formatter.format(amtMap[i2]) + "\n";
          }
          
        } catch (error) {
          console.log(error);
        }
      },
      async getRemarks(SpaceID, result) {
        try {
          const response = await GetPricing(SpaceID);
          result.Remark = response.data.Remark;
        } catch (error) {
          console.log(error);
        }
      },
      checkAll() {
        for (let index in this.carriers) {
          this.checkedCarriers[this.carriers[index]] = true;
        }
        this.filterCarrier();
      },
      uncheckAll() {
        for (let index in this.carriers) {
          
          this.checkedCarriers[this.carriers[index]] = false;
        }
        this.filterCarrier();
      },
      filterCarrier() {
        
        this.showResult();
      },
      showResultF(field) {
        this[field] = null;
        this.showResult();
      },
      showResult() {

        this.results = [];

        for (var index in this.allResults) {
          
          let singleResult = this.allResults[index];
         
          let isShow = true;
          
          if (singleResult.ETD != null && singleResult.ETD != '') {
            
            if (this.filter_etd_from != null && this.filter_etd_from != '') {
              if (singleResult.ETD < this.filter_etd_from) {
                isShow = false;
              } 
            } 
            if (this.filter_etd_to != null && this.filter_etd_to != '') {
              if (singleResult.ETD > this.filter_etd_to) {
                isShow = false;
              } 
            } 
          }

          
          if (singleResult.ETA != null && singleResult.ETA != '') {
            
            if (this.filter_eta_from != null && this.filter_eta_from != '') {
              if (singleResult.ETA < this.filter_eta_from) {
                isShow = false;
              } 
            } 
            if (this.filter_eta_to != null && this.filter_eta_to != '') {
              if (singleResult.ETA > this.filter_eta_to) {
                isShow = false;
              } 
            } 
          }

          if (!this.checkedCarriers[singleResult.PrCode]) {
            isShow = false;
          }


          singleResult.isShowDiscount = this.isValidDiscount(singleResult);
          this.showDiscount();
          if (isShow) {
            this.results.push(singleResult);
          }
        }
      },
      async sendInquiry() {
        try {
          
          let UserID = localStorage.getItem('userid');
          this.searchParams['user'] = UserID;
          const response = await SendInquiry(this.searchParams);
          if (response.data.isSuccess) {  
            this.snackbar = true;
            this.message = "Inquiry is sent.";
            this.message_color = "primary";
          } else {
            if (response.data.Key == 'EMAIL_EMPTY') {
              this.message = "Please update your email address in your user profile first.";
            } else {
              this.message = "Busy. Please try again later!";
            }
            this.snackbar = true;
            this.message_color = "error";
          }
          this.dialog = false;
        } catch (error) {
          console.log(error);
        }
      },
      qtyMessage() {
        return this.qty_message;
      },
      alertMessage() {
        if (this.alert_message  == undefined || this.alert_message == "") {
          return false;
        } else {
          return true;
        }
      },
      portFromMessage() {
        return this.port_from_message;
      },
      portToMessage() {
        return this.port_to_message;
      },
      calShow(i) {
        if (i === 1) {
          return false;
        } else {
          return true;
        }
      },
      sortingBy(criteria) {

          if (criteria == 'Cheapest') {
              this.results.sort(compareCheapest); 
          } else if (criteria == 'Fastest') {
              this.results.sort(compareFastest); 
          } else if (criteria == 'Earliest') {
              this.results.sort(compareEarliest); 
          }
      },
      checkTEU(teu) {
        if (teu > 0) {
          return false;
        } else {
          return true;
        }
      },
      toBookingForm(result) {
        let query = { SpaceID: result.SpaceID, SeaType: result.SeaType };

        if (result.qty20 != null && result.qty20 != 0) {
          query['q20'] = result.qty20;
        }
        
        if (result.qty40 != null && result.qty40 != 0) {
          query['q40'] = result.qty40;
        }
        
        if (result.qty40hq != null && result.qty40hq != 0) {
          query['q40hq'] = result.qty40hq;
        }
        
        if (result.qty45hq != null && result.qty45hq != 0) {
          query['q45hq'] = result.qty45hq;
        }

        if (this.discount != null) {
          query['discount'] = this.discount.DiscountCode;
        }
        this.$router.push({name:'booking_form',query: query});
      },
      async search_quotes(e) {
        if (typeof(e) === 'function') {
          e.preventDefault();
        }
        
        this.port_from_message = "";
        this.port_to_message = "";
        this.alert_message = "";
        this.alert = false;
        this.qty_message = "";

        let params = {};
        
        params["type"] = this.type;

        if (this.origin == null) {
          this.port_from_message = "Required";
        } else {
          params["origin"] = this.origin.PortCode;
          params["origin_name"] = this.origin.PortName;
        }
        
        if (this.destination == null) {
          this.port_to_message = "Required";
        } else {
          params["destination"] = this.destination.PortCode;
          params["destination_name"] = this.destination.PortName;
        }
        params["q20"] = this.q20 == null ? 0 : this.q20;
        params["q40"] = this.q40 == null ? 0 : this.q40;
        params["q40hq"] = this.q40hq == null ? 0 : this.q40hq;
        params["q45hq"] = this.q45hq == null ? 0 : this.q45hq;
        params["earliestEtd"] = this.earliestEtd;
        params["filterEtdFrom"] = this.filter_etd_from;
        params["filterEtdTo"] = this.filter_etd_to;
        params["filterEtaFrom"] = this.filter_eta_from;
        params["filterEtaTo"] = this.filter_eta_to;      

        if ((params["q20"] + params["q40"] + params["q40hq"] + params["q45hq"]) == 0) {
            this.alert_message = "Must select at least 1 container";
            this.alert = true;
            this.qty_message = "*";
        }

        if (this.alert_message != "" || this.port_from_message != "" || this.port_to_message != "") {
          return;
        }

        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2
        });

        this.searchParams = params;
        try {
          let i = 0;
          this.services = [];
          this.allResults = [];
          this.carriers = [];
          this.checkedCarriers = {};
          const response = await SearchPricing(params);

          if (response.data.length == 0) {
            this.dialog = true;
            return;
          }

          if (response.data[index].Service != '') {
            response.data[index].ServiceCode  = "(" + response.data[index].ServiceCode + ")";
          }
          for (var index in response.data) {
              let total = 
                  response.data[index].GP20 * this.q20 +
                  response.data[index].GP40 * this.q40 +
                  response.data[index].HQ40 * this.q40hq +
                  response.data[index].HQ45 * this.q45hq;
              if ((this.q20 + this.q40 + this.q40hq + this.q45hq) > 0 && total == 0) {
                  continue;
              }

              if (this.carriers.indexOf(response.data[index].PrCode.trim()) < 0) {
                this.carriers.push(response.data[index].PrCode);
                this.checkedCarriers[response.data[index].PrCode] = true; 
              }
              response.data[index].TT = response.data[index].ViaTT + response.data[index].PodTT + response.data[index].PldTT;
              
              if (response.data[index].ETD != '' && response.data[index].ETD != null) {
                if (response.data[index].ETD.length > 10) {
                  response.data[index].ETD = response.data[index].ETD.substring(0,10)
                }
                let ETD = new Date(response.data[index].ETD);
                let PolETD = new Date(response.data[index].ETD);
                let PodETA = new Date(response.data[index].ETD);
                let ETA = new Date(response.data[index].ETD);
                PolETD.setDate(ETD.getDate() + response.data[index].ViaTT);
                PodETA.setDate(ETD.getDate() + response.data[index].ViaTT  + response.data[index].PodTT );
                ETA.setDate(ETD.getDate() + response.data[index].TT);
                response.data[index].PolETD = PolETD.getFullYear() + "-" + ("0"+(PolETD.getMonth()+1)).slice(-2) + "-" + ("0"+PolETD.getDate()).slice(-2);
                response.data[index].PodETA = PodETA.getFullYear() + "-" + ("0"+(PodETA.getMonth()+1)).slice(-2) + "-" + ("0"+PodETA.getDate()).slice(-2);
                response.data[index].ETA = ETA.getFullYear() + "-" + ("0"+(ETA.getMonth()+1)).slice(-2) + "-" + ("0"+ETA.getDate()).slice(-2);
              }


              response.data[index].qty20 = this.q20;
              response.data[index].qty40 = this.q40;
              response.data[index].qty40hq = this.q40hq;
              response.data[index].qty45hq = this.q45hq;
              response.data[index].SeaType = this.type;
              
              response.data[index].Total = total;
              response.data[index].TotalF = formatter.format(total);
              response.data[index].TotalAll ="USD " + formatter.format(total);

              response.data[index].id = i++;

              response.data[index].GP20F = (response.data[index].GP20 == 0)?"N/A":formatter.format(response.data[index].GP20);
              response.data[index].GP40F = (response.data[index].GP40 == 0)?"N/A":formatter.format(response.data[index].GP40);
              response.data[index].HQ40F = (response.data[index].HQ40 == 0)?"N/A":formatter.format(response.data[index].HQ40);
              response.data[index].HQ45F = (response.data[index].HQ45 == 0)?"N/A":formatter.format(response.data[index].HQ45);
              
              response.data[index].Currency = "USD";
              response.data[index].details = null;
              response.data[index].isShowDetail = false;
              response.data[index].isShowDiscount = false;

              this.getLocalCharges(response.data[index].SpaceID, response.data[index]);

              this.allResults.push(response.data[index]);
          }
          this.showResult();
          this.sortingBy(this.sortingCriteria);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  function getQuantity() {
        var qty = [];      
        for (var i=0,m=100;i<=m; i++) {
          qty.push(i);
        }
        return qty;
  }

  function compareCheapest(a, b) {
      return compareField(a, b, 'Total')
  }

  function compareFastest(a, b) {
      return compareField(a, b, 'PolPodTT')
  }

  function compareEarliest(a, b) {
      return compareField(a, b, 'Etd')
  }

  function compareField(a, b, field) {
      const c1 = a[field];
      const c2 = b[field];

      let comparison = 0;
      if (c1 > c2) {
          comparison = 1;
      } else if (c1 < c2) {
          comparison = -1;
      }
      return comparison;
  }

</script>

<style scoped>
.qty {
  width: 80px;
  max-width: 100px;
}
.port {
  width: 350px;
}
.date {
  width: 200px;
}

.subtitle {
  background-color: #e8e8e8;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-container {
  margin: 10px;
  padding: 10px;
}

.detail-title {
  background-color: #e8e8e8;
}

.detail-tab {
  margin-right: 1px;
  height: 60px;
  background-color: #e8e8e8;
}

.prx_table_title th {
  font-size: xx-small;
  text-align: left;
}

.prx_table_th th {
  border: 1px solid;
  font-size: smaller;
  background-color: lightgrey;
  color: black;
}

.prx_table_td td {
  border: 1px solid;
  font-size: smaller;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
}

.table-title {
  text-align: right;
}

.btn {
    font-family: 'Century Gothic';
}

.v-tab--disabled {
    pointer-events: none;
    opacity: 1;
}

pre {
 overflow-x: auto; /* Use horizontal scroller if needed; for Firefox 2, not needed in Firefox 3 */
 white-space: pre-wrap; /* css-3 */
 white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
 white-space: -pre-wrap; /* Opera 4-6 */
 white-space: -o-pre-wrap; /* Opera 7 */
 /* width: 99%; */
 word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
