<template>
  <v-autocomplete
    v-model="port"
    :items="portOptions"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    auto-select-first
    item-text="PortName"
    item-value="AMSPortCode"
    :label="label"
    :searchType="searchType"
    prepend-icon="mdi-database-search"
    return-object no-filter
    dense
    :error-messages="errorMessages"
  ></v-autocomplete>
</template>

<script>
import _ from "lodash";
import { SelectPortApi } from "@/api/publicServices";
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    cachedPorts: {
      type: Array
    },
    errorMessages: {
      type:String 
    },
    
    searchType: {
      type: String,
      required: true
    },
  },
  data: () => ({
    portOptions: [],
    isLoading: false,
    search: null,
    port: null
  }),

  computed: {},
  created: function() {
    this.debouncedSearchPort = _.debounce(this.searchPort, 500);
    this.debouncedFilterCachedPort = _.debounce(this.filterCachedPorts, 500);
  },
  methods: {
    async searchPort(searchVal) {
      if (searchVal == null) return; 
      if (this.isLoading) return;
      if (searchVal.length === 0) return;
      if (!_.isEmpty(this.port) && this.port.PortName === searchVal) return;
      this.isLoading = true;
      try {
        const response = await SelectPortApi(searchVal, this.searchType);
        this.isLoading = false;
        this.portOptions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    filterCachedPorts(searchVal) {
      if (this.isLoading) return;
      if (searchVal.length === 0) return;
      const searchText = searchVal.toLowerCase();
      this.portOptions = this.cachedPorts.filter(port => {
        return port.PortName.toLowerCase().indexOf(searchText) > -1;
      });
      this.isLoading = false;
    }, 
    clearPorts() {
      console.log(this.label + ": " + "clearPorts")
      this.$nextTick(() => {
        this.port = null;
        this.portOptions = [];
      });
    }
  },
  watch: {
    search(newVal) {
      if (this.cachedPorts && this.cachedPorts.length > 0) {
        this.debouncedFilterCachedPort(newVal);
      } else {
        this.debouncedSearchPort(newVal);
      }
    },
    port() {
      this.$emit("input", this.port);
    }
  }
};
</script>