import axios from "axios";

const KEY = "525b58d59b7a9569fb7795f30735410a";
const GET_GEO_CODE = "https://restapi.amap.com/v3/geocode/geo";

const GET_DRIVING_DETAIL = "https://restapi.amap.com/v5/direction/driving";

const GOOGLE_API_KEY = "AIzaSyBuAXymne0y0yxS_k-Nq75fCjpoBLK6sUo";
const GET_GOOGLE_GEO_CODE = "https://maps.googleapis.com/maps/api/geocode/json";



const GetGeoCode = (address) => {
  const data = axios.get(GET_GEO_CODE, {
    params: {
      address: address,
      key: KEY
    }
  });

  return data;
}


const GetGoogleGeoCode = (address) => {
  const data = axios.get(GET_GOOGLE_GEO_CODE, {
    params: {
      address: address,
      key: GOOGLE_API_KEY
    }
  });

  return data;
}



const GetDrivingDetail = (origin, destination) => {
  const data = axios.get(GET_DRIVING_DETAIL, {
    params: {
      origin: origin,
      destination: destination,
      key: KEY
    }
  });

  return data;
}


export { GetGeoCode, GetDrivingDetail, GetGoogleGeoCode };

