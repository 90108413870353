<template>
  <div class="agent" id="agent">
    <main-menu></main-menu>
    <v-app id="inspire" style="background-color:#f3f3f3" >
      <v-card class="my-5 mx-auto" outlined max-width="100%" max-height="100%">
        <v-container dense >
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row no-gutters>
              <v-col class=" " cols="10">
                <v-row class="pt-5">
                  <v-col class="port py-0" cols="2">
                    <v-select
                      v-model="type"
                      :items="types"
                      dense
                      label="Type"
                      @change="clearPorts()"
                    ></v-select>
                  </v-col>
                  <v-col class="port py-0" cols="4">
                    <select-port
                      v-model="origin"
                      item-text="name"
                      item-value="code"
                      label="From"
                      required
                      ref="origin_port"
                      :searchType="type"
                      :rules="portRules"
                      hide-no-data
                      prepend-icon="mdi-database-search"
                      :error-messages="portFromMessage()"
                    ></select-port>
                  </v-col>
                  <v-col class="port py-0" cols="4">
                    <select-port
                      v-model="destination"
                      item-text="name"
                      item-value="code"
                      label="To"
                      required
                      ref="destination_port"
                      :searchType="type"
                      :rules="portRules"
                      hide-no-data
                      prepend-icon="mdi-database-search"
                      :error-messages="portToMessage()"
                    ></select-port>
                  </v-col>
                </v-row>
                <v-row class="py-0">
                  <!--
                  <v-col class="py-0" cols="5">
                    <v-text-field label="Origin Pickup" @click="getGeoCode" prepend-icon="mdi-home" dense readonly v-model="warehouseName" clearable></v-text-field>
                  </v-col>
                  -->
                  <v-col class="date py-0" cols="3">
                    <v-menu
                      ref="etd_menu"
                      v-model="etd_menu"
                      :close-on-content-click="false"
                      :return-value.sync="earliestEtd"
                      transition="scale-transition"
                      offset-y
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="earliestEtd"
                          label="Cargo Ready Date"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          class="date"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="earliestEtd"
                        :min="minDate"
                        :max="maxDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="earliestEtd = null">Clear</v-btn>
                        <v-btn color="primary" @click="etd_menu = false">Cancel</v-btn>
                        <v-btn color="primary" @click="$refs.etd_menu.save(earliestEtd)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  
                  <v-col class="port py-0" cols="2">
                    <v-text-field label="CBM" prepend-icon="mdi-cube-scan" dense 
                      v-model="cbm"  @keypress="isNumber" clearable v-show="type=='LCL'"></v-text-field>
                  </v-col>
                  <v-col class="port py-0" cols="2">
                    <v-text-field label="TON" prepend-icon="mdi-scale" dense 
                      v-model="ton"  @keypress="isNumber" clearable v-show="type=='LCL'"></v-text-field>
                    <v-text-field label="KGS" prepend-icon="mdi-scale" dense 
                      v-model="kgs" @keypress="isNumber" clearable v-show="type=='AIR'"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-0 mx-0" dense>
                  
                  <v-col class=" py-0">
                  <v-alert dense type="error" density="compact" v-model="alert">{{alert_message}}</v-alert>
                  </v-col>

                </v-row>
              </v-col>
              <v-col cols="2" class="mx-auto pb-0">
                <v-card-actions>
                <v-btn color="primary" dense @click="search_quotes">Search</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
      <v-container class="mx-auto">
          
        <v-row >
          <v-col cols=2>
              
            <v-form ref="form" v-model="filter">
            <v-card class="mx-auto px-0" outlined>
              <v-card-title>Filters</v-card-title>
              <v-card flat class="pa-0">
                
              <v-card-subtitle class="subtitle ">
                <v-row no-gutters >
                  <v-col>
                    Carrier 
                    <v-icon @click="checkAll" small>mdi-check</v-icon>
                    <v-icon @click="uncheckAll" small>mdi-close</v-icon>
                  </v-col>
                  
                </v-row>
              </v-card-subtitle>
              
                  
              
                
              <v-row v-for="carrier in carriers" :key="carrier" no-gutters>
                <v-col class="px-5">  
                  <input type="checkbox"  @change="filterCarrier()" v-model="checkedCarriers[carrier]" /> {{carrier}}
                </v-col>

              </v-row>
              </v-card>
            </v-card>
            
            </v-form>
          </v-col>

          <v-col class="py-0 px-0 results"  min-width:500px>
            <v-row no-gutters class="py-0 px-0" v-show="true">
                 
                <v-col class="d-flex px-3" cols="3" >
                  <v-select
                      dense
                      v-model="sortingCriteria"
                      :items="sortedBy"
                      label="Sorted By"
                      value="Cheapest 20'"
                      outlined
                      background-color="lightgray"
                      @change="sortingBy"
                  ></v-select>
                 
                </v-col>
                 
                <v-col>
                <b class='px-2 soc-text' >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </b>
                &nbsp;= SOC
              </v-col>
            </v-row>

            <!-- START SEA -->
            <v-row no-gutters class="py-0 px-0" v-show="isSea">
              <v-col v-for="result in results" :key="result.id" cols="12" class="result py-0">
                <v-card class="card-container py-0">
                  <v-row>
                    <v-col class="py-0">
                      <v-row no-gutters class="ma-0 py-5 px-2">
                        
                        <v-col cols="1" class="mb-0 pb-0">
                            <v-img contain :aspect-ratio="1" width="150px" height="60px" :src="`${publicPath}images/pr/${result.PrCode}.png`">
                            </v-img>
                        </v-col>
                        
                        <v-col cols="2" class="ma-auto">
                          <v-card width="100%" height="100%" flat >
                            <v-row no-gutters >
                              <b>Effect: {{result.EffectiveDate}}</b>
                            </v-row>
                            <v-row no-gutters >
                              <b>Expiry: {{result.ExpiryDate}}</b>
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col cols="9" class="py-1">
                          <v-row no-gutters class="ma-0 pa-0">
                            <v-col class="mb-0 pb-0">
                                <b>
                                {{result.PolName}} 
                                </b>
                                <small>({{result.TT}} days)</small> <v-icon color="red">mdi-arrow-right</v-icon>
                                <b>
                                {{result.PldName}}
                                </b>
                                <b class='pl-2' v-show="lastType2=='FCL'">
                                {{result.ServiceCode}}
                                </b>
                                
                            </v-col>
                            <table class="mx-0" min-width="400px" v-show="lastType2=='FCL'">
                              <thead class="prx_table_th">
                                <th style="min-width:70px">20' GP</th>
                                <th style="min-width:70px">40' GP</th>
                                <th style="min-width:70px">40' HQ</th>
                                <th style="min-width:70px">45' HQ</th>
                                <th style="min-width:70px">40' NOR</th>
                              </thead>
                              <tbody class="prx_table_td">
                                <td v-bind:class="getClass(result.isSOC20)">{{result.GP20F}}</td>
                                <td v-bind:class="getClass(result.isSOC40)">{{result.GP40F}}</td>
                                <td v-bind:class="getClass(result.isSOC40HQ)">{{result.HQ40F}}</td>
                                <td v-bind:class="getClass(result.isSOC45)">{{result.HQ45F}}</td>
                                <td v-bind:class="getClass(result.isSOCNOR40)">{{result.NOR40F}}</td>
                              </tbody>
                            </table>
                            <v-col v-show="lastType2=='LCL'" class="text-right">
                              <h2 class="pr-10"> {{ result.FreightFee + result.TruckFee +result.TtlLChgFee }} </h2>
                            </v-col>
                          </v-row>
                          
                          <v-row no-gutters class="ma-0 pa-0">
                            <v-tabs background-color="transparent" v-model="result.details">
                              
                              <v-tab @click="showDetail(result, 0)">
                                Charge Detail
                              </v-tab>  
                              <v-tab @click="showDetail(result, 1)">
                                <div v-if="lastType2=='FCL'">Service Routing</div>
                                <div v-else>Schedule</div>
                              </v-tab>
                              <v-tab @click="showDetail(result, 2)">
                                Commodity 
                              </v-tab>
                              <v-tab @click="showDetail(result, 3)">
                                Remark
                              </v-tab>
                              <v-tab @click="showDetail(result, 4)" v-show="lastType2=='LCL'">
                                Destination Charge
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="result.details">
                              <v-tab-item>
                                <v-card outlined v-show="result.isShowDetail0" class="mr-10" width="100%"  min-width="300">
                                  <v-card v-show="lastType2=='FCL'"  >
                                    <table class="mx-0" min-width="400px" v-show="lastType2=='FCL'">
                                      <thead class="prx_table_th">
                                        <th style="min-width:100px">&nbsp;</th>
                                        <th style="min-width:100px">20' GP</th>
                                        <th style="min-width:100px">40' GP</th>
                                        <th style="min-width:100px">40' HQ</th>
                                        <th style="min-width:100px">45' HQ</th>
                                        <th style="min-width:100px">40' NOR</th>
                                      </thead>
                                      <tbody class="prx_table_td freight_tr">
                                        <tr>
                                          <td>Freight</td>
                                          <td>{{ (result.GP20F == "N/A")?"N/A":formatter.format((result.isSOC20)?result.SOC20:result.GP20)}}</td>
                                          <td>{{ (result.GP40F == "N/A")?"N/A":formatter.format((result.isSOC40)?result.SOC40:result.GP40)}}</td>
                                          <td>{{ (result.HQ40F == "N/A")?"N/A":formatter.format((result.isSOC20)?result.SOC40HQ:result.HQ40)}}</td>
                                          <td>{{ (result.HQ45F == "N/A")?"N/A":formatter.format((result.isSOC45)?result.SOC45:result.HQ45)}}</td>
                                          <td>{{ (result.NOR40F == "N/A")?"N/A":formatter.format((result.isSOCNOR40)?result.SOCNOR40:result.NOR40)}}</td>
                                        </tr>
                                        <tr v-show="isShowTruckFee">
                                          <td>Truck Fee</td>
                                          <td>{{ (result.GP20F == "N/A")?"N/A":formatter.format(result.TruckFee20) }}</td>
                                          <td>{{ (result.GP40F == "N/A")?"N/A":formatter.format(result.TruckFee40)}}</td>
                                          <td>{{ (result.HQ40F == "N/A")?"N/A":formatter.format(result.TruckFee40)}}</td>
                                          <td>{{ (result.HQ45F == "N/A")?"N/A":formatter.format(result.TruckFee40)}}</td>
                                          <td>{{ (result.NOR40F == "N/A")?"N/A":formatter.format(result.TruckFee40)}}</td>
                                        </tr>
                                        
                                        <tr v-for="(Charge, index) in result.Charges" :key="index">
                                          <td>{{ Charge.ChgName }}</td>
                                          <td>{{ (Charge.is20)?Charge.SellUnitPrice:"N/A"}}</td>
                                          <td>{{ (Charge.is40)?Charge.SellUnitPrice:"N/A"}}</td>
                                          <td>{{ (Charge.is40H)?Charge.SellUnitPrice:"N/A"}}</td>
                                          <td>{{ (Charge.is45)?Charge.SellUnitPrice:"N/A"}}</td>
                                          <td>{{ (Charge.is40N)?Charge.SellUnitPrice:"N/A"}}</td>
                                        </tr>

                                        <tr>
                                          <td>Total</td>
                                          <td>{{result.GP20F}}</td>
                                          <td>{{result.GP40F}}</td>
                                          <td>{{result.HQ40F}}</td>
                                          <td>{{result.HQ45F}}</td>
                                          <td>{{result.NOR40F}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </v-card>
                                  <v-card v-show="lastType2=='LCL'"  >
                                    <table class="mx-0" min-width="400px" v-show="lastType2=='LCL'">
                                      <thead class="prx_table_th">
                                        <th style="min-width:100px">&nbsp;</th>
                                        <th style="min-width:100px">Unit Price</th>
                                        <th style="min-width:100px">Unit</th>
                                        <th style="min-width:100px">Charge</th>
                                      </thead>
                                      <tbody class="prx_table_td freight_tr">
                                        <tr>
                                          <td>Freight</td>
                                          <td>{{ result.UnitPrice}}</td>
                                          <td>{{ result.Unit}}</td>
                                          <td>{{ result.FreightFee}}</td>
                                        </tr>
                                        <tr v-show="isShowTruckFee">
                                          <td>Truck Fee</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>{{ result.TruckFee}}</td>
                                        </tr>
                                        
                                        <tr v-for="(Charge, index) in result.Charges" :key="index">
                                          <td>{{ Charge.ChgName }}</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>{{ Charge.SellUnitPrice}}</td>
                                        </tr>
                                        <tr>
                                          <td>Total</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>{{result.FreightFee + result.TruckFee + result.TtlLChgFee }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </v-card>
                                </v-card>
                                
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="result.isShowDetail1" class="mr-10" width="100%"  min-width="300">
                                  <v-card v-show="lastType2=='LCL'"  >
                                    <v-card-text>
                                      <pre>T/T: {{result.TT}}<br />CFS Closing: {{result.CFSClosingWkDay}}<br />ETD: {{result.ETDWkDay}}</pre>
                                    </v-card-text>
                                  </v-card>
                                  <v-card v-show="lastType2=='FCL'" flat width="100%" >
                                    <v-timeline dense clipped>
                                      
                                      <v-timeline-item small v-show="result.PlrCnty != ''">
                                        <v-chip label color="primary" ><country-flag :country='result.PlrCnty' size='normal'/>{{result.PlrName}}</v-chip>
                                      </v-timeline-item>

                                      <v-timeline-item small>
                                        <v-chip label color="primary" ><country-flag :country='result.PolCnty' size='normal'/>{{result.PolName}}</v-chip>
                                      </v-timeline-item>

                                      <v-timeline-item small hide-dot v-show="result.PolViaTT > 0">
                                        <small>{{result.PolViaTT}} days</small>
                                      </v-timeline-item>

                                      <v-timeline-item small v-show="result.ViaCnty != ''">
                                        <v-chip label color="primary" ><country-flag :country='result.ViaCnty' size='normal'/>{{result.ViaName}}</v-chip>
                                      </v-timeline-item>

                                      
                                      <v-timeline-item small hide-dot v-show="result.PolPodTT > 0">
                                        <small>{{result.PolPodTT}} days</small>
                                      </v-timeline-item>

                                      <v-timeline-item small v-show="result.PodCnty != '' && result.PodAMSCode != result.PldAMSCode">
                                        <v-chip label color="primary" ><country-flag :country='result.PodCnty' size='normal'/>{{result.PodName}}</v-chip>
                                      </v-timeline-item>

                                      <v-timeline-item small hide-dot v-show="result.PodPldTT > 0">
                                        <small>{{result.PodPldTT}} days</small>
                                      </v-timeline-item>

                                      <v-timeline-item small >
                                        <v-chip label color="primary" ><country-flag :country='result.PldCnty' size='normal'/>{{result.PldName}}</v-chip>
                                      </v-timeline-item>

                                    </v-timeline>
                                  </v-card>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="result.isShowDetail2"  min-width="300">
                                  <v-card-text>
                                    <pre>{{result.CommodityDetail}}</pre>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="result.isShowDetail3" min-width="300">  
                                  <v-card-text>
                                    <pre>{{result.Remark}}</pre>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="result.isShowDetail4 && lastType2=='LCL'"  min-width="300">
                                  <v-card-text>
                                    <pre>{{result.DestChgRemark}}</pre>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!-- END SEA -->

            
            <!-- START AIR -->
            
            <v-row no-gutters class="py-0 px-0" v-show="isAir">
              <v-col v-for="resultAir in resultAirs" :key="resultAir.PricingID" cols="12" class="result py-0">
                <v-card class="card-container py-0">
                  <v-row>
                    <v-col class="py-0">
                      
                      <v-row no-gutters class="ma-0 pa-5">
                        
                        <v-col cols="1" class="mb-0 pb-0">
                            <v-card-title>{{resultAir.AlCode}}</v-card-title>
                        </v-col>
                        <v-col cols="2" class="ma-auto">
                          <v-card width="100%" height="100%" flat >
                            <v-row no-gutters >
                              <b>Effect: {{resultAir.EffectiveDate}}</b>
                            </v-row>
                            <v-row no-gutters >
                              <b>Expiry: {{resultAir.ExpiryDate}}</b>
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col cols="9" class="py-1">
                          <v-row no-gutters class="ma-0 pa-0">
                            <v-col class="mb-0 pb-0">
                                <b>
                                {{resultAir.DptName}} 
                                </b>
                                <small>({{resultAir.TT}} days)</small>  <v-icon color="red">mdi-arrow-right</v-icon>
                                <b>
                                {{resultAir.DestName}}
                                </b>
                            </v-col>
                            
                            <v-col v-show="lastType2=='AIR'" class="text-right">
                              <h2 class="pr-10"> {{ resultAir.FreightFee + resultAir.TruckFee + resultAir.TtlLChgFee }} </h2>
                            </v-col>
                          </v-row>
                          
                          <v-row no-gutters class="ma-0 pa-0">
                            <v-tabs background-color="transparent" v-model="resultAir.details">
                              <v-tab @click="showDetail(resultAir, 0)">
                                Charge Detail
                              </v-tab>
                              <v-tab @click="showDetail(resultAir, 1)">
                                Service Routing
                              </v-tab>
                              <v-tab @click="showDetail(resultAir, 2)">
                                Commodity 
                              </v-tab>
                              <v-tab @click="showDetail(resultAir, 3)">
                                Remark
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="resultAir.details">
                              
                              <v-tab-item>
                                <v-card outlined v-show="resultAir.isShowDetail0"  min-width="300">
                                  
                                  <v-card>
                                    <table class="mx-0" min-width="400px" v-show="lastType2=='AIR'">
                                      <thead class="prx_table_th">
                                        <th style="min-width:100px">&nbsp;</th>
                                        <th style="min-width:100px">Amount</th>
                                        <th style="min-width:60px">N</th>
                                        <th style="min-width:60px">Q45</th>
                                        <th style="min-width:60px">Q100</th>
                                        <th style="min-width:60px">Q300</th>
                                        <th style="min-width:60px">Q500</th>
                                        <th style="min-width:60px">Q1000</th>
                                        <th style="min-width:60px">Min.</th>
                                      </thead>
                                      <tbody class="prx_table_td freight_tr">
                                        <tr>
                                          <td>Freight</td>
                                          <td>{{ resultAir.FreightFee}}</td>
                                          <td>{{ (resultAir.N==0)?'-':resultAir.N}}</td>
                                          <td>{{ (resultAir.Q45==0)?'-':resultAir.Q45}}</td>
                                          <td>{{ (resultAir.Q100==0)?'-':resultAir.Q100}}</td>
                                          <td>{{ (resultAir.Q300==0)?'-':resultAir.Q300}}</td>
                                          <td>{{ (resultAir.Q500==0)?'-':resultAir.Q500}}</td>
                                          <td>{{ (resultAir.Q1000==0)?'-':resultAir.Q1000}}</td>
                                          <td>{{ (resultAir.MinChg==0)?'-':resultAir.MinChg}}</td>
                                        </tr>
                                        <tr  v-show="isShowTruckFee">
                                          <td>Truck Fee</td>
                                          <td>{{ resultAir.TruckFee}}</td>
                                          <td colspan="7">&nbsp;</td>
                                        </tr>
                                        <tr v-for="(Charge, index) in resultAir.Charges" :key="index">
                                          <td>{{ Charge.ChgName }}</td>
                                          <td>{{ Charge.SellUnitPrice}}</td>
                                          <td colspan="7">&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>Total</td>
                                          <td>{{resultAir.FreightFee + resultAir.TruckFee + resultAir.TtlLChgFee }}</td>
                                          <td colspan="7">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </v-card>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="resultAir.isShowDetail1" class="mr-10" width="100%"  min-width="300">
                                  <v-timeline dense clipped>
                                    
                                    <v-timeline-item small v-show="resultAir.DptCnty != ''">
                                      <v-chip label color="primary" ><country-flag :country='resultAir.DptCnty' size='normal'/>{{resultAir.DptName}}</v-chip>
                                    </v-timeline-item>


                                    <v-timeline-item small hide-dot v-show="resultAir.TT > 0">
                                      <small>{{resultAir.TT}} days</small>
                                    </v-timeline-item>

                                    <v-timeline-item small v-show="resultAir.DestCnty != ''">
                                      <v-chip label color="primary" ><country-flag :country='resultAir.DestCnty' size='normal'/>{{resultAir.DestName}}</v-chip>
                                    </v-timeline-item>

                                  </v-timeline>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="resultAir.isShowDetail2"  min-width="300">
                                  <v-card-text>
                                    <pre>{{resultAir.Commodity}}</pre>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card outlined v-show="resultAir.isShowDetail3"  min-width="300">
                                  <v-card-text>
                                    <pre>{{resultAir.Remark}}</pre>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!-- END AIR -->

          </v-col>
        </v-row>
      </v-container>

      <v-snackbar centered :color="message_color" v-model="snackbar">
          {{ this.message }}
          <v-btn text @click="snackbar = false" style="float:right" >
              <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-snackbar>

      <v-dialog v-model="dialog" max-width="500" >
          <v-card>
              <v-card-title class="headline">Notice</v-card-title>
      
              <v-card-text>
                  Oops, the rates you are searching is currently not available, would you like to send an inquiry to us?”
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
      
                  <v-btn color="#92D050" text @click="dialog = false" class="btn">
                  No, Thanks
                  </v-btn>
      
                  <v-btn color="#92D050" dark tile @click="sendInquiry" class="btn">
                  Yes
                  </v-btn>
              </v-card-actions>
          </v-card>
          
      </v-dialog>



      
      <v-dialog v-model="warehouseDialog"  >
          <v-card>
              <v-card-title class="headline">Origin Pickup</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="10">
                    <v-text-field v-model="warehouseAddress" label="Address" clearable></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    
                    <v-btn color="#92D050" dark tile class="btn" @click="selectWarehouse">
                     Search
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card flat :v-show="warehouseSelects.length > 0">
                  
                  <v-radio-group v-model="warehouseSelected">
                    <v-radio v-for="(warehouse, index) in warehouseSelects" :key="index" :label="toWarehouseLabel(warehouse)" :value="warehouse"></v-radio>
                  </v-radio-group>
                </v-card>
                <v-row v-show="isShowWarehouseMessage">
                  <v-col>
                      No Result.
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#92D050" dark tile  @click="warehouseDialog = false" class="btn">Close</v-btn>
                  <v-btn color="#92D050" dark tile  @click="warehouseConfirm" class="btn">Confirm</v-btn>
              </v-card-actions>



              <!--<div  class="mapContainer" :id="uuid" ></div>-->


          </v-card>
          
      </v-dialog>

    </v-app>
  </div>
</template>

<!--<script src="https://webapi.amap.com/maps?v=2.0s&key=d3e3cc8ebfb08032685981ad65b161f0"></script> -->

<script>
  import Vue from "vue";
  import MainMenu from "@/components/Menu";
  import SelectPort from "@/components/Select/SelectPort.vue";
  import CountryFlag from 'vue-country-flag'
  import { SendInquiry, SearchAgentFCLPricing, SearchAgentLCLPricing, SearchAgentAirPricing } from "@/api/publicServices";
  import { GetGeoCode, GetGoogleGeoCode } from "@/api/geoServices";
  
  import backgroundUrl from "@/assets/quotes_background_image.jpg";
  //import AMapLoader from '@amap/amap-jsapi-loader';
  //import {uuid} from '@/utils/tools';

  Vue.component("main-menu", { MainMenu });
  Vue.component('country-flag', CountryFlag);  
  
 
  export default {
    components: { MainMenu, SelectPort: SelectPort },
    mounted () {
      
    },
    created() { 
       /*
      window._AMapSecurityConfig = {
        securityJsCode:'167c0b2fb259569470743783338c7b7d',
      }
      console.log(this.uuid);
          this.initMap();
          */
          this.formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    data: () => ({
      publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
      backgroundStyle: {
        "background-image": `url(${backgroundUrl})`,
        "background-repeat": "no-repeat",
        "background-size": "cover"
      },
      tabs: [],
      filter: null,
      valid: true,
      quantity:getQuantity(),
      types:['FCL', 'LCL', 'AIR'],
      type: 'FCL',
      sortedByFCL: ['Carrier', 'Cheapest 20\'', 'Cheapest 40\'', 'Cheapest 40\'HQ', 'Cheapest 45\'', 'Cheapest 40\'NOR'],
      sortedByLCL: ['Carrier','Cheapest CBM', 'Cheapest KGs'],
      sortedByAir: ['Airline','Cheapest +45', 'Cheapest +100', 'Cheapest +300', 'Cheapest +500', 'Cheapest +1000'],
      sortedBy : [],
      earliestEtd: new Date().toISOString().substring(0, 10),
      etd_menu: false,
      modal: false,
      menu2: false,
      origin: null,
      destination: null,
      minDate: new Date().toISOString().substring(0, 10),
      landscape: false,
      reactive: false,
      filter_etd_from: null,
      filter_etd_to: null,
      filter_eta_from: null,
      filter_eta_to: null,
      filter_etd_from_menu: false,
      filter_etd_to_menu: false,
      filter_eta_from_menu: false,
      filter_eta_to_menu: false,
      filter_direct: false,
      transshipments: [],
      sortingCriteria: '',
      allResults: [],
      allAirResults: [],
      results: [],
      resultAirs: [],
      portRules: [v => !!v || "This field is required"],
      lazy: false,
      port_from_message: "",
      port_to_message: "",
      alert_message:"",
      alert:false,
      qty_message: "",
      snackbar: false,
      message:"",
      message_color:"primary",
      searchParams: {},
      carriers:[],
      checkedCarriers: {},
      isSea: true,
      isAir:false,
      lastType: "FCL",
      lastType2: "FCL",
      dialog: false,
      warehouseDialog: false,
      //uuid: uuid(16),
      warehouseAddress: '',
      warehouseLocation: '',
      warehouseName: '',
      warehouseSelects : [],
      warehouseSelected: null,
      isShowWarehouseMessage: false,
      map: null,
      formatter: null,
      cbm: 0,
      ton: 0,
      kgs: 0,
      isShowTruckFee: false,
    }),
    computed: {
      maxDate() {
        var endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate.toISOString().substr(0, 10);
      },
    },
    methods: {
      getClass(isShowSOC) {
        return (isShowSOC)?"soc-text":"";
      },
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      toWarehouseLabel(warehouse)
      {
        return warehouse.formatted_address + '{' + warehouse.location + '}';
      },

      warehouseConfirm() {
        this.warehouseDialog = false;
        this.warehouseName = this.warehouseSelected.formatted_address;
        this.warehouseLocation = this.warehouseSelected.location;
      },
      getGeoCode() {
        this.alert_message = "";  
        this.alert = false;      
        if (this.origin == null) {
          this.alert_message = "Please enter origin first";
          
          this.alert = true;
          return;
        }
        this.warehouseDialog = true;
      },
      initMap() {
        /*      
        AMapLoader.load({
          "key": "d3e3cc8ebfb08032685981ad65b161f0",  
          "version": "2.0",   
          "plugins": [],           
        }).then((AMap)=>{
          this.map = new AMap.Map(this.uuid);
          console.log(this.map);
        }).catch(e => {
          console.log(e);
        });
        this.warehouseDialog= false;
        */
      },
      async selectWarehouse() {
        console.log(this.origin.PortCode.startsWith("CN"));
        if (!this.origin.PortCode.startsWith("CN")) {
          return this.selectWarehouseByGoogle();
        }
        try {
          this.warehouseSelects = [];
          this.warehouseSelected = null;
          this.isShowWarehouseMessage = false;

          const response = await GetGeoCode(this.warehouseAddress);

          if (response.data.status == "1") {
            this.warehouseSelects = response.data.geocodes;
            if (this.warehouseSelects.length > 0) {
              this.warehouseSelected = this.warehouseSelects[0];
            }
          } else {
            this.isShowWarehouseMessage = true;
          }
        } catch (error) {
          console.log(error);
        }
        
      },
      
      async selectWarehouseByGoogle() {
        
        try {
          this.warehouseSelects = [];
          this.warehouseSelected = null;
          this.isShowWarehouseMessage = false;

          const response = await GetGoogleGeoCode(this.warehouseAddress);
          console.log(response.data.results[0].geometry.location);
          if (response.data.status == "OK") {
            let location = response.data.results[0].geometry.location;
            let latlng = location.lng + ',' + location.lat;
            this.warehouseSelects = [{formatted_address:  response.data.results[0].formatted_address, location: latlng }];
            this.warehouseSelected = this.warehouseSelects[0];
          } else {
            this.isShowWarehouseMessage = true;
          }
        } catch (error) {
          console.log(error);
        }
        
      },
      changeType() {
        this.clearPorts();
      },
      async sendInquiry() {
        try {
          
          let UserID = localStorage.getItem('userid');
          this.searchParams['user'] = UserID;
          const response = await SendInquiry(this.searchParams);
          if (response.data.isSuccess) {  
            this.snackbar = true;
            this.message = "Inquiry is sent.";
            this.message_color = "primary";
          } else {
            if (response.data.Key == 'EMAIL_EMPTY') {
              this.message = "Please update your email address in your user profile first.";
            } else {
              this.message = "Busy. Please try again later!";
            }
            this.snackbar = true;
            this.message_color = "error";
          }
          this.dialog = false;
        } catch (error) {
          console.log(error);
        }
      },
      changeSorting() {
        
        if (this.type == "AIR") {
          this.sortedBy = this.sortedByAir;
        } else if (this.type == "FCL") {
          this.sortedBy = this.sortedByFCL;
        } else if (this.type == "LCL") {
          this.sortedBy = this.sortedByLCL;
        }

        this.sortingCriteria = this.sortedBy[0];
      },
      clearPorts() {

        if ((this.type == "FCL" && this.lastType == "LCL") || (this.type == "LCL" && this.lastType == "FCL")) {
          //console.log('not reset')
          this.lastType = this.type;
          return;
        } else {
          //console.log('reset')
          this.origin = null ;
          this.destination = null;
          this.$refs.destination_port.clearPorts();
          this.$refs.origin_port.clearPorts();
          this.lastType = this.type;
          return;
        }
      },
      showDetail(result, position) {
        
        if (result.isShowDetail0 && position == 0) {
          result.isShowDetail0 = false;
          return;
        }
        if (result.isShowDetail1 && position == 1) {
          result.isShowDetail1 = false;
          return;
        }
        if (result.isShowDetail2 && position == 2) {
          result.isShowDetail2 = false;
          return;
        }
        if (result.isShowDetail3 && position == 3) {
          result.isShowDetail3 = false;
          return;
        }
        if (result.isShowDetail4 && position == 4) {
          result.isShowDetail4 = false;
          return;
        }
        result.isShowDetail0=false;
        result.isShowDetail1=false;
        result.isShowDetail2=false;
        result.isShowDetail3=false;
        result.isShowDetail4=false;
        if (position == 0) {
          result.isShowDetail0 = true;
        } else if (position == 1) {
          result.isShowDetail1 = true;
        } else if (position == 2) {
          result.isShowDetail2 = true;
        } else if (position == 3) {
          result.isShowDetail3 = true;
        } else if (position == 4) {
          result.isShowDetail4 = true;
        } 
      },
      checkAll() {
        for (let index in this.carriers) {
          this.checkedCarriers[this.carriers[index]] = true;
        }
        this.filterCarrier();
      },
      uncheckAll() {
        for (let index in this.carriers) {
          
          this.checkedCarriers[this.carriers[index]] = false;
        }
        this.filterCarrier();
      },
      filterCarrier() {
        if (this.isAir) {
          this.showAirResult();
        } else {
          this.showResult();
        }
      },
      showResult() {
        this.results = [];
        for (var index in this.allResults) {
          let singleResult = this.allResults[index];
          let isShow = true;
          if (!this.checkedCarriers[singleResult.PrCode]) {
            isShow = false;
          }
          if (isShow) {
            this.results.push(singleResult);
          }
        }
      },
      showAirResult() {
        this.resultAirs = [];
        for (var index in this.allAirResults) {
          let singleResult = this.allAirResults[index];
          let isShow = true;
          if (!this.checkedCarriers[singleResult.AlCode]) {
            isShow = false;
          }
          if (isShow) {
            this.resultAirs.push(singleResult);
          }
        }
      },
      qtyMessage() {
        return this.qty_message;
      },
      alertMessage() {
        if (this.alert_message  == undefined || this.alert_message == "") {
          return false;
        } else {
          return true;
        }
      },
      portFromMessage() {
        return this.port_from_message;
      },
      portToMessage() {
        return this.port_to_message;
      },
      calShow(i) {
        if (i === 1) {
          return false;
        } else {
          return true;
        }
      },
      sortingBy(criteria) {
          if (criteria == 'Carrier') {
              sortByKey(this.results, 'PrCode');
          } else if (criteria == 'Airline') {
              sortByKey(this.resultAirs, 'AlCode');
          } else if (criteria == 'Cheapest 20\'') {
              sortByKey(this.results, 'GP20');
          } else if (criteria == 'Cheapest 40\'') {
              sortByKey(this.results, 'GP40');
          } else if (criteria == 'Cheapest 40\'HQ') {
              sortByKey(this.results, 'HQ40');
          } else if (criteria == 'Cheapest 45\'') {
              sortByKey(this.results, 'HQ45');
          }  else if (criteria == 'Cheapest 40\'NOR') {
              sortByKey(this.results, 'NOR40');
          } else if (criteria == 'Cheapest CBM') {
              sortByKey(this.results, 'PerCbm');
          } else if (criteria == 'Cheapest KGs') {
              sortByKey(this.results, 'PerKgs');
          } else if (criteria == 'Cheapest +45') {
              sortByKey(this.resultAirs, 'Q45');
          } else if (criteria == 'Cheapest +100') {
              sortByKey(this.resultAirs, 'Q100');
          } else if (criteria == 'Cheapest +300') {
              sortByKey(this.resultAirs, 'Q300');
          } else if (criteria == 'Cheapest +500') {
              sortByKey(this.resultAirs, 'Q500');
          } else if (criteria == 'Cheapest +1000') {
              sortByKey(this.resultAirs, 'Q1000');
          } 
      },
      search_quotes(e) {
        if (typeof(e) === 'function') {
          e.preventDefault();
        }
        this.port_from_message = "";
        this.port_to_message = "";
        this.alert_message = "";
        this.alert = false;
        this.qty_message = "";
        this.lastType2 = this.type;
        this.isShowTruckFee = false;

        let params = {};
        params["type"] = this.type;

        if ( (this.type == 'LCL' || this.type == 'AIR' ) ) {
          if (isNaN(this.cbm)) {
            this.alert_message = "Cbm is invalid";
          } else {
            params["cbm"] = this.cbm;
          }
        }
        
        
        if ( this.type == 'LCL'){
          if (isNaN(this.ton)) {
            this.alert_message = "TON is invalid";
          } else if (Number(this.ton) <= 0 && Number(this.cbm) <= 0) {
            this.alert_message = "TON or CBM must be geater than 0";
          } else {
            params["ton"] = this.ton;
          }
        }
        
        if ( this.type == 'AIR'){

          if (isNaN(this.kgs)) {
            this.alert_message = "KG is invalid";
          } else if (Number(this.kgs) <= 0 && Number(this.cbm) <= 0) {
            this.alert_message = "KG or CBM must be geater than 0";
          } else {
            params["kgs"] = this.kgs;
          }
        }

        if (this.origin == null) {
          this.port_from_message = "Required";
        } else {
          params["origin"] = this.origin.PortCode;
          params["origin_name"] = this.origin.PortName;
        }
        
        if (this.destination == null) {
          this.port_to_message = "Required";
        } else {
          params["destination"] = this.destination.PortCode;
          params["destination_name"] = this.destination.PortName;
        }

        if (this.warehouseName != null) {
          params["warehouse"] = this.warehouseLocation;
          params["warehouse_name"] = this.warehouseName;
        }

        params["earliestEtd"] = this.earliestEtd;
        params["UserID"] = localStorage.getItem("userid");
        if (this.alert_message != "" ) {
          this.alert = true;
          return;
        } 
        
        if(this.port_from_message != "" || this.port_to_message != "") {
          return;
        }

        this.searchParams = params;

        this.services = [];
        this.allAirResults = [];
        this.carriers = [];
        this.checkedCarriers = {};
        this.allResults = [];
        this.results = [];
        this.resultAirs = [];

        if (this.type == "AIR") {
          this.isAir = true;
          this.isSea = false;
          this.search_air_quotes(params);
        } else {
          this.isAir = false;
          this.isSea = true;
          this.search_sea_quotes(params);
        }

        this.changeSorting();  
      },
      async search_air_quotes(params) {
        

        try {
          let i = 0;
          const response = await SearchAgentAirPricing(params);

          if (response.data.length == 0) {
            this.dialog = true;
            return;
          }

          for (var index in response.data) {

              if (this.carriers.indexOf(response.data[index].AlCode.trim()) < 0) {
                this.carriers.push(response.data[index].AlCode);
                this.checkedCarriers[response.data[index].AlCode] = true; 
              }
              
              response.data[index].id = i++;

              response.data[index].Q45F = (response.data[index].Q45 === 0)?"N/A":this.formatter.format(response.data[index].Q45);
              response.data[index].Q100F = (response.data[index].Q100 === 0)?"N/A":this.formatter.format(response.data[index].Q100);
              response.data[index].Q300F = (response.data[index].Q300 === 0)?"N/A":this.formatter.format(response.data[index].Q300);
              response.data[index].Q500F = (response.data[index].Q500 === 0)?"N/A":this.formatter.format(response.data[index].Q500);
              response.data[index].Q1000F = (response.data[index].Q1000 === 0)?"N/A":this.formatter.format(response.data[index].Q1000);
              
              response.data[index].Currency = "USD";
              response.data[index].details = null;
              response.data[index].isShowDetail0 = false;
              response.data[index].isShowDetail1 = false;
              response.data[index].isShowDetail2 = false;
              response.data[index].isShowDetail3 = false;
              response.data[index].isShowDetail4 = false;
              
              if (response.data[index].EffectiveDate.length > 0) {
                response.data[index].EffectiveDate = response.data[index].EffectiveDate.substr(0,10);
              }

              if (response.data[index].ExpiryDate.length > 0) {
                response.data[index].ExpiryDate = response.data[index].ExpiryDate.substr(0,10);
              }
              
              if (response.data[index].TruckFee == 0) {
                this.isShowTruckFee = false;
              } else {
                this.isShowTruckFee = true;
              }

              let TtlLChgFee = 0;
              
              for (var chgIndex in response.data[index].Charges) {
                let UnitPrice = response.data[index].Charges[chgIndex].SellUnitPrice;
                TtlLChgFee += UnitPrice;
              }
              
              response.data[index].TtlLChgFee = TtlLChgFee;
              
              this.allAirResults.push(response.data[index]);
          }
          this.showAirResult();
          this.sortingBy(this.sortingCriteria);
        } catch (error) {
          console.log(error);
        }
      },
      search_sea_price(params) {
        if (this.type == "FCL") {
          return SearchAgentFCLPricing(params);
        } else {
          return SearchAgentLCLPricing(params);
        }
      },
      async search_sea_quotes(params) {
        try {
          let i = 0;
          const response = await this.search_sea_price(params);

          if (response.data.length == 0) {
            this.dialog = true;
            return;
          }

          for (var index in response.data) {

              if (this.carriers.indexOf(response.data[index].PrCode.trim()) < 0) {
                this.carriers.push(response.data[index].PrCode);
                this.checkedCarriers[response.data[index].PrCode] = true; 
              }

              
                //console.log(response.data[index].PolPodTT )
                //console.log(response.data[index].PodPldTT )
                response.data[index].TT = response.data[index].PolPodTT +  response.data[index].PodPldTT;
              
              response.data[index].SeaType = this.type;
              
              response.data[index].id = i++;

              if (response.data[index].Service != '') {
                response.data[index].ServiceCode  = "(" + response.data[index].ServiceCode + ")";
              }

              if (params.type == "FCL") {
                let Ttl20LChgFee = 0;
                let Ttl40LChgFee = 0;
                let Ttl40HLChgFee = 0;
                let Ttl45LChgFee = 0;
                let Ttl40NLChgFee = 0;
                for (var chgIndex in response.data[index].Charges) {
                  let UnitPrice = response.data[index].Charges[chgIndex].SellUnitPrice
                  response.data[index].Charges[chgIndex].is20 = false;
                  response.data[index].Charges[chgIndex].is40 = false;
                  response.data[index].Charges[chgIndex].is40H = false;
                  response.data[index].Charges[chgIndex].is45 = false;
                  response.data[index].Charges[chgIndex].is40N = false;
                  let unit = response.data[index].Charges[chgIndex].Unit.trim();
                  if (unit == '20') {
                    response.data[index].Charges[chgIndex].is20 = true;
                    Ttl20LChgFee += UnitPrice;
                  } else if (unit == '40') {
                    response.data[index].Charges[chgIndex].is40 = true;
                    response.data[index].Charges[chgIndex].is40N = true;
                    
                    Ttl40LChgFee += UnitPrice;
                    Ttl40NLChgFee += UnitPrice;
                  } else if (unit == '40H' || unit == '40HQ') {
                    response.data[index].Charges[chgIndex].is40H = true;
                    Ttl40HLChgFee += UnitPrice;
                  } else if (unit == '45') {
                    response.data[index].Charges[chgIndex].is45 = true;
                    Ttl45LChgFee += UnitPrice;
                  } else  {
                    response.data[index].Charges[chgIndex].is20 = true;
                    response.data[index].Charges[chgIndex].is40 = true;
                    response.data[index].Charges[chgIndex].is40H = true;
                    response.data[index].Charges[chgIndex].is45 = true;
                    response.data[index].Charges[chgIndex].is40N = true;
                    Ttl20LChgFee += UnitPrice;
                    Ttl40LChgFee += UnitPrice;
                    Ttl40HLChgFee += UnitPrice;
                    Ttl45LChgFee += UnitPrice;
                    Ttl40NLChgFee += UnitPrice;
                  }
                } 

                if (response.data[index].isSOC20) {
                  response.data[index].GP20F = (response.data[index].SOC20 == 0)?"N/A":this.formatter.format(response.data[index].SOC20 + response.data[index].TruckFee20 + Ttl20LChgFee);
                } else {
                  response.data[index].GP20F = (response.data[index].GP20 == 0)?"N/A":this.formatter.format(response.data[index].GP20 + response.data[index].TruckFee20 + Ttl20LChgFee);
                }
                
                if (response.data[index].isSOC40) {
                  response.data[index].GP40F = (response.data[index].SOC40 == 0)?"N/A":this.formatter.format(response.data[index].SOC40 + response.data[index].TruckFee40 + Ttl40LChgFee);
                } else {
                  response.data[index].GP40F = (response.data[index].GP40 == 0)?"N/A":this.formatter.format(response.data[index].GP40 + response.data[index].TruckFee40 + Ttl40LChgFee);                  
                }
                
                if (response.data[index].isSOC40HQ) {
                  response.data[index].HQ40F = (response.data[index].SOC40HQ == 0)?"N/A":this.formatter.format(response.data[index].SOC40HQ + response.data[index].TruckFee40 + Ttl40HLChgFee);
                } else {
                  response.data[index].HQ40F = (response.data[index].HQ40 == 0)?"N/A":this.formatter.format(response.data[index].HQ40 + response.data[index].TruckFee40 + Ttl40HLChgFee);
                }

                if (response.data[index].isSOC45) {
                  response.data[index].HQ45F = (response.data[index].SOC45 == 0)?"N/A":this.formatter.format(response.data[index].SOC45 + response.data[index].TruckFee40 + Ttl45LChgFee);
                } else {
                  response.data[index].HQ45F = (response.data[index].HQ45 == 0)?"N/A":this.formatter.format(response.data[index].HQ45 + response.data[index].TruckFee40 + Ttl45LChgFee);
                }
                
                if (response.data[index].isSOCNOR40) {
                  response.data[index].NOR40F = (response.data[index].SOCNOR40 == 0)?"N/A":this.formatter.format(response.data[index].SOCNOR40 + response.data[index].TruckFee40 + Ttl40NLChgFee);
                } else {
                  response.data[index].NOR40F = (response.data[index].NOR40 == 0)?"N/A":this.formatter.format(response.data[index].NOR40 + response.data[index].TruckFee40 + Ttl40NLChgFee);
                }
                  
                if (response.data[index].TruckFee20 == 0 && response.data[index].TruckFee40 == 0) {
                  this.isShowTruckFee = false;
                } else {
                  this.isShowTruckFee = true;
                }
              } else {


                let TtlLChgFee = 0;
                
                for (var chgIndex2 in response.data[index].Charges) {
                  let UnitPrice = response.data[index].Charges[chgIndex2].SellUnitPrice;
                  TtlLChgFee += UnitPrice;
                }
                
                response.data[index].TtlLChgFee = TtlLChgFee;

                response.data[index].PerCbmF = (response.data[index].PerCbm == 0)?"N/A":this.formatter.format(response.data[index].PerCbm + response.data[index].TruckFeeCbm);
                response.data[index].PerKgsF = (response.data[index].PerKgs == 0)?"N/A":this.formatter.format(response.data[index].PerKgs + response.data[index].TruckFeeKg);
                response.data[index].MinChgF = (response.data[index].MinChg == 0)?"N/A":this.formatter.format(response.data[index].MinChg + response.data[index].TruckFeeMinChg);
                response.data[index].CommodityDetail = response.data[index].Commodity;
                
                if (response.data[index].TruckFee == 0) {
                  this.isShowTruckFee = false;
                } else {
                  this.isShowTruckFee = true;
                }

              }
              response.data[index].Currency = "USD";
              response.data[index].details = null;
              response.data[index].isShowDetail0 = false;
              response.data[index].isShowDetail1 = false;
              response.data[index].isShowDetail2 = false;
              response.data[index].isShowDetail3 = false;
              response.data[index].isShowDetail4 = false;


              
              let PlrAMSCode = response.data[index].PlrAMSCode;
              let PolAMSCode = response.data[index].PolAMSCode;
              let ViaAMSCode = response.data[index].ViaAMSCode;
              let PodAMSCode = response.data[index].PodAMSCode;
              let PldAMSCode = response.data[index].PldAMSCode;
              response.data[index].PolCnty = PolAMSCode.substr(0,2);
              response.data[index].PldCnty = PldAMSCode.substr(0,2);
              if (PodAMSCode.length > 0) {
                response.data[index].PodCnty = PodAMSCode.substr(0,2);
              } else {
                response.data[index].PodCnty = "";
              }

              if (PlrAMSCode.length > 0) {
                response.data[index].PlrCnty = PlrAMSCode.substr(0,2);
              } else {
                response.data[index].PlrCnty = "";
              }

              if (ViaAMSCode != undefined && ViaAMSCode.length > 0) {
                response.data[index].ViaCnty = ViaAMSCode.substr(0,2);
              } else {
                response.data[index].ViaCnty = "";
              }

              if (PldAMSCode.length > 0) {
                response.data[index].PldCnty = PldAMSCode.substr(0,2);
              } else {
                response.data[index].PldCnty = "";
              }

              if (response.data[index].EffectiveDate.length > 0) {
                response.data[index].EffectiveDate = response.data[index].EffectiveDate.substr(0,10);
              }

              if (response.data[index].ExpiryDate.length > 0) {
                response.data[index].ExpiryDate = response.data[index].ExpiryDate.substr(0,10);
              }
              this.allResults.push(response.data[index]);
          }
          this.showResult();
          this.sortingBy(this.sortingCriteria);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  function getQuantity() {
        var qty = [];      
        for (var i=0,m=100;i<=m; i++) {
          qty.push(i);
        }
        return qty;
  }

  function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

</script>

<style scoped>
.qty {
  width: 80px;
  max-width: 100px;
}
.port {
  width: 350px;
}
.date {
  width: 200px;
}

.subtitle {
  background-color: #e8e8e8;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-container {
  margin: 10px;
  padding: 10px;
}

.detail-title {
  background-color: #e8e8e8;
}

.detail-tab {
  margin-right: 1px;
  height: 60px;
  background-color: #e8e8e8;
}

.prx_table_title th {
  font-size: xx-small;
  text-align: left;
}

.prx_table_th th {
  border: 1px solid;
  font-size: smaller;
  background-color: lightgrey;
  color: black;
}

.prx_table_td td {
  border: 1px solid;
  font-size: smaller;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
}

.freight_tr tr td {
  text-align: right;
}

.table-title {
  text-align: right;
}

.btn {
    font-family: 'Century Gothic';
}

.v-tab--disabled {
    pointer-events: none;
    opacity: 1;
}

pre {
 overflow-x: auto; /* Use horizontal scroller if needed; for Firefox 2, not needed in Firefox 3 */
 white-space: pre-wrap; /* css-3 */
 white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
 white-space: -pre-wrap; /* Opera 4-6 */
 white-space: -o-pre-wrap; /* Opera 7 */
 /* width: 99%; */
 word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.mapContainer {
  width: 100%;
  height: 100%;
  
}
.soc-text {
  background: #00FF00;
}
</style>
