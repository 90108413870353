<template>
    <v-app id="register">
        <div id="main-menu" >
            <v-card color="grey lighten-4" flat class="mx-auto">
                <v-toolbar dark class="menu">
                    <v-toolbar-title><v-img src="@/assets/logo_ratex_dark.png"></v-img></v-toolbar-title>
                    
                    <v-spacer></v-spacer>
                    <v-btn icon to="/home" class="mx-auto">
                        <v-icon>mdi-home</v-icon> Home
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon to="/search_quotes">
                        <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon to="/register">
                        <v-icon>mdi-account</v-icon> Register
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
                
                <v-spacer background-color="lightblue"></v-spacer>
            </v-card>
            
        </div>
        <form>
            <v-card elevation="24" min-width="900" class="mx-auto register">
                <v-row>
                    <v-col>
                        <div>
                            <v-chip color="primary" label>
                                <v-icon left>mdi-account-circle-outline</v-icon>
                                USER
                            </v-chip>
                        </div>
                        <div>
                            <v-text-field label="Email" :rules="emailRules" required v-model="form.email" ></v-text-field>
                        </div>
                        <div>
                            <v-text-field label="Password" type="password" :rules="rules" required v-model="form.password" ></v-text-field>
                        </div>
                        <div>
                            <v-text-field label="Confirm Password" type="password" :rules="rules" required v-model="form.password_confirmation" ></v-text-field>
                        </div>
                    </v-col>
                    <v-col>
                        <div>
                            <v-chip
                                color="primary"
                                label
                            >
                                <v-icon left>mdi-account-circle-outline</v-icon>
                                COMPANY INFO
                            </v-chip>
                        </div>
                        <div>
                            <v-text-field label="PIC" :rules="rules" required v-model="form.pic" ></v-text-field>
                        </div>
                        <div>
                            <v-text-field label="Title" :rules="title_rules" required v-model="form.title" ></v-text-field>
                        </div>
                        <div>
                            <v-text-field label="Company Name" :rules="rules" required v-model="form.name" ></v-text-field>
                        </div>
                        <div>
                        <SelectCountry
                            v-model="country"
                            item-text="CountryName"
                            item-value="CountryCode"
                            label="Country"
                            required
                            :rules="rules"
                            hide-no-data
                            prepend-icon="mdi-database-search"
                            ></SelectCountry>
                        </div>
                        
                        <div>
                        <SelectCity
                            v-model="city"
                            item-text="PortName"
                            item-value="AMSPortCode"
                            :itemref="getCountryCode()"
                            label="City"
                            required
                            :rules="rules"
                            hide-no-data
                            prepend-icon="mdi-database-search"
                            ></SelectCity>
                        </div>
                        <div>
                            <v-textarea label="Address" :rules="rules" required v-model="form.address" ></v-textarea>
                        </div>
                        <br/>
                    </v-col>
                    
                        
                </v-row>
                <v-row class="mx-auto" style="width:100%">
                    <div class="notice mx-auto">
                        <v-btn color="#92D050" dark class="register_btn btn" large tile @click="dialog = true"><span style="font-size:x-large;text-transform:none;">Register</span></v-btn>
                    </div>
                </v-row>
                
                
            </v-card>
        
            <v-snackbar centered :color="message_color" v-model="snackbar">
                {{ this.message }}
                <v-btn text @click="snackbar = false" style="float:right" >
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-snackbar>

            <v-dialog v-model="dialog" max-width="500" >
                <v-card>
                    <v-card-title class="headline">Confirmation</v-card-title>
            
                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                Email
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{form.email}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                PIC
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{form.pic}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                Title
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{form.title}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                Country
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{country.CountryName}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                Company Name
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{form.name}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                City
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{city.PortName}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                Address
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{form.address}}
                            </v-card-subtitle>
                        </v-col>
                    </v-row>

                    <v-card-actions>
                        <v-spacer></v-spacer>
            
                        <v-btn color="#92D050" text @click="dialog = false" class="btn">
                        Cancel
                        </v-btn>
            
                        <v-btn color="#92D050" dark tile @click="register" class="btn">
                        Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
        </form>
    </v-app>
</template>

<script>
    import { RegisterUser } from "@/api/publicServices";
    
    import SelectCountry from "@/components/Select/SelectCountry.vue";
    import SelectCity from "@/components/Select/SelectCity.vue";
    export default {
        props : ["nextUrl"],
        
        components: { SelectCountry: SelectCountry, SelectCity: SelectCity },
        
        data() {
            return {
                rules: [
                value => !!value || 'Required.',
                value => (value && value.length >= 3) || 'Min 3 characters',
                ],
                title_rules: [
                value => !!value || 'Required.',
                value => (value && value.length >= 2) || 'Min 3 characters',
                ],
                
                emailRules: [
                    value => !!value || 'Required.',
                    value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                    },
                ],
                form : {
                    name : "",
                    address : "",
                    email : "",
                    password : "",
                    password_confirmation : "",
                    pic : "",
                    title : "",
                    city : "",
                    cityname : "",
                    country : ""
                }, 
                country: {
                    CountryCode :"",
                    CountryName :""
                },
                city: {
                    AMSPortCode: "",
                    PortName:""
                },
                snackbar: false,
                message:"test",
                message_color:"primary",
                dialog: false,
            }
        },
        methods : {
            isEmpty(field) {

                this.snackbar = true;
                this.message_color = "error";
                this.message = field + " cannot be empty";
            },
            getCountryCode() {
                if (this.country == null) {
                    return "";
                }
                return this.country.CountryCode;
            },
            async register(e) {
                e.preventDefault();
                
                if (this.form.email.length == 0) {
                    this.isEmpty("Email");
                    return;
                }
                
                if (this.country.CountryCode.length == 0) {
                    this.isEmpty("Country");
                    return;
                }
                
                if (this.city.AMSPortCode.length == 0) {
                    this.isEmpty("City");
                    return;
                }
               
                if (this.form.title.length == 0) {
                    this.isEmpty("Title");
                    return;
                }
                
                if (this.form.pic.length == 0) {
                    this.isEmpty("PIC");
                    return;
                }

                if (this.form.name.length == 0) {
                    this.isEmpty("Company Name");
                    return;
                }

                if (this.form.address.length == 0) {
                    this.isEmpty("Company Address");
                    return;
                }

                if (this.form.password === this.form.password_confirmation && this.form.password.length > 0)
                {
                    try {
                        this.form.country = this.country.CountryCode;
                        this.form.city = this.city.AMSPortCode;
                        this.form.cityname = this.city.PortName; 
                        const response = await RegisterUser(this.form);

                        if (response.data.isSuccess) {
                            this.snackbar = true;
                            this.message = "User is created successfully.";
                            this.message_color = "primary";
                            let $r = this.$router;
                            setTimeout(function() { 
                                $r.push('home'); 
                            }, 3000);
                            
                        } else {
                            this.snackbar = true;
                            this.message_color = "error";
                            if (response.data.Msg == "EXISTED") {
                                this.message = "User is already registered.";
                            } else {
                                this.message = response.data.Msg;         
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    this.form.password = ""
                    this.form.password_confirmation = ""


                    this.snackbar = true;
                    this.message = "Passwords do not match.";
                    this.message_color = "error";

                }
                

            }
        }
    }
</script>


<style scoped>
.register {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        padding: 20px;
        margin-top: 50px;
        margin-right: 70px;
    }

.textbox {

        width: 100%;
        border: 1px solid #CCCCCC;
}
.smaller {
    font-size: smaller
}


.btn {
    font-family: 'Century Gothic';
}
.register_btn {
    width: 100%;
}
</style>