import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
  ? window.location.origin + '/DtsService'
  : "http://dts-log.com:8083/DtsService",
  //timeout: 5000,
  headers: {
    'Content-type': 'application/json;',
  },
});

export default httpClient;
