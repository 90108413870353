<template>
    <div class="booking">
        <main-menu></main-menu>
        <v-app id="inspire">
            <v-simple-table height="500px">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Booking No.</th>
                        <th class="text-left">Vessel</th>
                        <th class="text-left">Reference</th>
                        <th class="text-left">Cut Off Date</th>
                        <th class="text-left">POL</th>
                        <th class="text-left">PLD</th>
                        <th class="text-left">Container</th>
                        <th class="text-left">Price</th>
                        <th class="text-left">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in Bookings" :key="item.SONo" @dblclick="showDetail(item.SONo)">
                        <td>{{item.SONo}}</td>
                        <td>{{item.Vsl2Name}} <br /> {{item.Voy2}}</td>
                        <td>{{item.AgtRefNo}}</td>
                        <td>{{item.SICutoff}}</td>
                        <td>{{item.PolName}}</td>
                        <td>{{item.PldName}}</td>
                        <td><div style="white-space: pre-line;">{{item.Container}}</div></td>
                        <td><div style="white-space: pre-line;">{{item.Price}}</div></td>
                        <td>   
                            <div v-show="item.Validsw">     
                                <v-btn icon @click="toUpdateBooking(item.SONo)" color="pink"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn icon @click="confirmCancelBooking(item.SONo)" color="pink"><v-icon>mdi-delete</v-icon></v-btn>
                            </div> 
                            <div v-show="!item.Validsw">
                                Cancelled
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>

                
            </v-simple-table>
            
                <v-dialog
                    v-model="cancelDialog"
                    max-width="290"
                >
                    <v-card>
                    <v-card-title class="headline">Cancel Booking</v-card-title>
            
                    <v-card-text>
                        Do you want to cancel this booking?
                    </v-card-text>
            
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
            
                        <v-btn color="#92D050" text @click="cancelDialog = false" class="btn">
                        No, Thanks
                        </v-btn>
            
                        <v-btn color="#92D050" dark tile @click="cancelBooking" class="btn">
                        Yes
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>



                <v-dialog v-model="detailDialog" fullscreen >
                    <v-card>
                        <v-toolbar color='primary' dark>
                            <v-row>
                                <v-col cols=11>
                                    <v-toolbar-title >Booking Detail </v-toolbar-title>
                                </v-col>
                                <v-col cols=1>
                                    <v-btn @click="detailDialog=false" large icon ><v-icon>mdi-close</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <v-card class="mb-5 py-5">
                            <table style="width:100%">
                                <tr>
                                    <td cols="2">
                                    <v-card-subtitle class="py-0 detail-title">Carrier</v-card-subtitle>
                                    </td>
                                    <td cols="3">
                                    <v-card-subtitle class="py-0 detail-title">ETD</v-card-subtitle>
                                    </td>
                                    <td cols="2">
                                    <v-card-subtitle class="py-0 detail-title">TT</v-card-subtitle>
                                    </td>
                                    <td cols="3">
                                    <v-card-subtitle class="py-0 detail-title">ETA</v-card-subtitle>
                                    </td>
                                    <td cols="2">
                                    <v-card-subtitle class="py-0 detail-title">Vessel Voyage</v-card-subtitle>
                                    </td>
                                </tr>
                                <tr no-gutters>
                                    
                                    <td cols="2" rowspan="2">
                                    <v-img contain :aspect-ratio="1" width="150px" height="60px" :src="`${publicPath}images/pr/${this.info.PrCode}.png`">
                                    </v-img>  
                                    </td>
                                    
                                    <td cols="3">
                                    <v-card-text class="py-0">{{this.info.Etd}}</v-card-text>
                                    </td>

                                    <td cols="2">
                                    <v-card-text class="py-0">{{this.info.TT}} Days</v-card-text>
                                    </td>
                                    <td cols="3">
                                    <v-card-text class="py-0">{{this.info.Eta}}</v-card-text>
                                    </td>
                                    <td cols="2">
                                    <v-card-text class="py-0">{{this.info.VslName + " " + this.info.Voy}}</v-card-text>
                                    </td>
                                </tr>
                                <tr no-gutters>
                                    <td cols="3">
                                    <v-card-text class="py-1">{{this.info.PolName}}</v-card-text>
                                    </td>
                                    <td cols="2">&nbsp;</td>
                                    <td>
                                    <v-card-text class="py-1">{{this.info.PldName}}</v-card-text>
                                    </td>
                                    
                                </tr>
                                </table>
                        </v-card>

                        <v-card class="mb-5">
                            <v-row>
                            <v-col>
                                <v-card-title>Booking Party</v-card-title>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Name</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ActShName}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Tel</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ActShTel}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Address</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ActShAdd}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Reference</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.AgtRefNo}}</v-card-text>
                            </v-col>
                            </v-row>
                        </v-card>
                        
                        <v-card class="mb-5 shipper-info" v-show="info.ShName != ''">
                            <v-row>
                            <v-col>
                                <v-card-title>Shipper</v-card-title>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Name</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ShName}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Tel</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ShTel}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Address</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.ShAdd}}</v-card-text>
                            </v-col>
                            </v-row>
                        </v-card>
                        
                        <v-card class="mb-5 consignee-info" v-show="info.CnName != ''">
                            <v-row>
                            <v-col>
                                <v-card-title>Consignee</v-card-title>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col>
                                <v-card-text>{{info.CnName}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Tel</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.CnTel}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Address</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.CnAdd}}</v-card-text>
                            </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="mb-5 notify-party-info" v-show="info.NpName != ''">
                            <v-row>
                            <v-col>
                                <v-card-title>Notify Party</v-card-title>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Name</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.NpName}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Tel</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.NpTel}}</v-card-text>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="2">
                                <v-card-subtitle>Address</v-card-subtitle>
                            </v-col>
                            <v-col cols="10">
                                <v-card-text>{{info.NpAdd}}</v-card-text>
                            </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="mb-5">
                            <v-row>
                            <v-col>
                                <v-card-title>
                                Containers
                                </v-card-title>
                            </v-col>
                            </v-row>
                            
                            <v-row v-show="this.info.q20pkg > 0" class="mx-12">
                                <v-col >
                                <v-card-subtitle>20' GP X {{this.info.q20qty}}</v-card-subtitle>
                                </v-col>
                                <v-col >
                                <v-card-text>{{this.info.q20pkg}} {{this.info.q20pkgunit}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>@USD {{this.info.GP20F}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>USD {{this.info.GP20TF}} </v-card-text>
                                </v-col>
                            </v-row>

                            <v-row v-show="this.info.q40pkg > 0" class="mx-12">
                                <v-col >
                                <v-card-subtitle>40' GP X {{this.info.q40qty}}</v-card-subtitle>
                                </v-col>
                                <v-col >
                                <v-card-text>{{this.info.q40pkg}} {{this.info.q40pkgunit}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>@USD {{this.info.GP40F}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>USD {{this.info.GP40TF}} </v-card-text>
                                </v-col>
                            </v-row>
                            
                            <v-row v-show="this.info.q40hqpkg > 0" class="mx-12">
                                <v-col >
                                <v-card-subtitle>40' HQ X {{this.info.q40hqqty}}</v-card-subtitle>
                                </v-col>
                                <v-col >
                                <v-card-text>{{this.info.q40hqpkg}} {{this.info.q40hqpkgunit}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>@USD {{this.info.HQ40F}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>USD {{this.info.HQ40TF}} </v-card-text>
                                </v-col>
                            </v-row>

                            
                            <v-row v-show="this.info.q45hqpkg > 0" class="mx-12">
                                <v-col >
                                <v-card-subtitle>45' HQ X {{this.info.q45hqqty}}</v-card-subtitle>
                                </v-col>
                                <v-col >
                                <v-card-text>{{this.info.q45hqpkg}} {{this.info.q45hqpkgunit}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>@USD {{this.info.HQ45F}} </v-card-text>
                                </v-col>
                                <v-col>
                                <v-card-text>USD {{this.info.HQ45TF}} </v-card-text>
                                </v-col>
                            </v-row>
                            <v-card flat v-show="discount.DiscountCode != ''">
                            <v-row class="mx-12">
                                <v-col>
                                <v-card-subtitle>Discount X {{this.info.cntrQty}}</v-card-subtitle>
                                </v-col>
                                <v-col>
                                <v-card-text>{{discount.DiscountCode}}</v-card-text>
                                </v-col>
                                <v-col>
                                    <v-card-text>@{{discount.CurrCode}} {{discount.AmountF}}</v-card-text>
                                </v-col>
                                <v-col>
                                    <v-card-text>{{discount.Discount}}</v-card-text>
                                </v-col>
                            </v-row>
                            </v-card>
                            <v-card class='ma-5 pa-5' v-show="LocalCharges != null && LocalCharges.length > 0">
                            <v-card-title>Local Charges</v-card-title>
                            <v-row no-gutters v-for="LocalCharge in LocalCharges" :key="LocalCharge.ChgID">
                                <v-col cols=6 class='mx-5'>
                                    <small>{{LocalCharge.ChgName}}</small>
                                </v-col>
                                <v-col cols=1>
                                    <small>{{LocalCharge.IncCurrCode}}</small>
                                </v-col>
                                <v-col cols=4>
                                    <small>{{LocalCharge.IncUnitPriceF}} X {{LocalCharge.NoOfUnit}}</small>
                                </v-col>
                            </v-row>
                            </v-card>
                            <v-row class="mx-12">
                                <v-col >
                                <v-card-subtitle>Total</v-card-subtitle>
                                </v-col>
                                <v-col >
                                &nbsp;
                                </v-col>
                                <v-col>
                                &nbsp;
                                </v-col>
                                <v-col>
                                <v-card-text><pre>{{this.info.TotalAll}}</pre> </v-card-text>
                                </v-col>
                            </v-row>
                            
                        </v-card>
                        
                        <v-card class="mb-5">
                            <v-row>
                            <v-col>
                                <v-card-title>
                                Charges
                                </v-card-title>
                            </v-col>
                            </v-row>
                            
                            <v-row class="mx-12">
                                <v-col cols="5">
                                <v-card-text>Ocean Freight</v-card-text>
                                </v-col>
                                <v-col cols="7">
                                <v-card-text>{{info.FrtType}}</v-card-text>
                                </v-col>
                            </v-row>
                            
                            <v-row class="mx-12">
                                <v-col cols="5">
                                <v-card-text>Local Charge</v-card-text>
                                </v-col>
                                <v-col cols="7">
                                <v-card-text>{{info.OthChgType}}</v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="mb-5">
                            <v-row>
                            <v-col>
                                <v-card-title>
                                Cargo
                                </v-card-title>
                            </v-col>
                            </v-row>
                            
                            <v-row v-for="cargo in this.info.cargos" :key="cargo.commodity" class="mx-12">
                                <v-col cols="5">
                                <v-card-text>{{cargo.commodity}} </v-card-text>
                                </v-col>
                                <v-col cols="7">
                                <v-card-text>{{cargo.hscode}} </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row justify="center" align="center">
                            <v-col cols="10" sm="1">
                                <v-btn color="primary" @click="detailDialog = false" dark tile large>
                                Close
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>

        </v-app>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    import { SearchBooking, CancelBooking, GetBooking } from "@/api/publicServices";
    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu},
        mounted () {
            this.initResult();
        },
        data () {
            return {
                publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
                selectedSONo: "",
                cancelDialog: false,
                detailDialog: false,
                Bookings: [],
                info : {
                    ActShName :"",
                    ActShTel :"",
                    ActShAdd :"",
                    ShName :"",
                    ShTel :"",
                    ShAdd :"",
                    CnName :"",
                    CnTel :"",
                    CnAdd :"",
                    NpName :"",
                    NpTel :"",
                    NpAdd :"",
                    PrCode:"APLU",
                    Eta:"",
                    Etd:"",
                    VslName:"",
                    Voy:"",
                    PldName:"",
                    PodName:"",
                    PolName:"",
                    PolPodTT:"",
                    q20qty: 0,
                    q20pkg : 0,
                    q20pkgunit : 'KG',
                    q40qty: 0,
                    q40pkg : 0,
                    q40pkgunit : 'KG',
                    q40hqqty: 0,
                    q40hqpkg : 0,
                    q40hqpkgunit : 'KG',
                    q45hqqty: 0,
                    q45hqpkg : 0,
                    q45hqpkgunit : 'KG',
                    SeaType: "",
                    cargos: [{ index: Date.now(), commodity:"", hscode:""}]
                },
                discount: {
                    DiscountCode:""
                },
                LocalCharges:[],
                Filter: {

                }
            }
        },
        methods: {
            async initResult() {
                this.Bookings = [];
                try {
                    this.Filter.UserID = localStorage.getItem('userid');
                    const response = await SearchBooking(this.Filter);
                    this.Bookings = response.data;
                    for (let index in this.Bookings) {
                        let Container = "";
                        let Booking = this.Bookings[index];
                        if (Booking.Ttl20 > 0) {
                            Container += "20' GP X " + Booking.Ttl20;
                        } 
                        if (Booking.Ttl40 > 0) {
                            if (Container != "") {
                                Container += "\n";
                            }
                            Container += "40' GP X " + Booking.Ttl40;
                        }
                        if (Booking.Ttl40Hq > 0) {
                            if (Container != "") {
                                Container += "\n";
                            }
                            Container += "40' HQ X " + Booking.Ttl40Hq;
                        }
                        if (Booking.Ttl45 > 0) {
                            if (Container != "") {
                                Container += "\n";
                            }
                            Container += "45' HQ X " + Booking.Ttl45;
                        }
                        this.Bookings[index].Container = Container;
                        
                    }
                    console.log(response);
                } catch (e) {
                    console.log(e);
                }
            },
            toUpdateBooking(SONo) {
                let query = { SONo: SONo };
                this.$router.push({name:'update_booking',query: query});
            }, 
            confirmCancelBooking(SONo) {
                this.cancelDialog = true;
                this.selectedSONo = SONo;
            },
            async showDetail(SONo) {
                console.log(SONo);
                this.detailDialog = true;
                this.LocalCharges = [];
                let UserID = localStorage.getItem('userid');
                try {
                    const response = await GetBooking(SONo, UserID);
                    this.markingType = "undefined";
                    this.info = response.data;
                    this.info.cargos = [];
                    for (var index in this.info.OnlineBkgComdys) {
                        var OnlineBkgComdy = this.info.OnlineBkgComdys[index];
                        this.info.cargos.push({ index: Date.now(), commodity:OnlineBkgComdy.ComdyName, hscode:OnlineBkgComdy.ComdyCode});
                    }

                    this.info.VslName = this.info.Vsl2Name;
                    this.info.Voy = this.info.Voy2;
                    this.info.q20qty = 0;
                    this.info.q40qty = 0;
                    this.info.q40hqqty = 0;
                    this.info.q45hqqty = 0;
                    
                    this.info.q20pkg = 0;
                    this.info.q40pkg = 0;
                    this.info.q40hqpkg = 0;
                    this.info.q45hqpkg = 0;

                    this.info.q20pkgunit = "KG";
                    this.info.q40pkgunit = "KG";
                    this.info.q40hqpkgunit = "KG";
                    this.info.q45hqpkgunit = "KG";
                    let ETD = new Date(this.info.Etd);
                    let ETA = new Date(this.info.Eta);
                    console.log(ETD)
                    console.log(ETA)
                    this.info.TT = Math.ceil((ETA - ETD) / (1000 * 60 * 60 * 24)); 
                    this.info.Etd = this.info.Etd.substring(0,10);
                    this.info.Eta = this.info.Eta.substring(0,10);
                    if (this.info.ShName != "") {
                        this.showSH = true;
                    }
                    
                    if (this.info.CnName != "") {
                        this.showCN = true;
                    }
                    
                    if (this.info.NpName != "") {
                        this.showNP = true;
                    }
                    this.info.cntrs = [];
                    for (var index2 in this.info.OnlineBkgCntrs) {
                        let cntr = this.info.OnlineBkgCntrs[index2];
                        cntr.isSelected = (index2 == 0) ;
                        if (this.lastSelectedCntr == "") {
                            this.lastSelectedCntr = cntr.CntrID;
                        }
                        if (cntr.EqptCode == 'D20S') {
                            this.info.q20qty +=1;
                            this.info.q20pkg += cntr.Pkg;
                            this.info.q20pkgunit = cntr.PkgUnit;
                            cntr.EqptName = "20' GP";
                        } else if (cntr.EqptCode == 'D40S') {
                            this.info.q40qty +=1;
                            this.info.q40pkg += cntr.Pkg;
                            this.info.q40pkgunit = cntr.PkgUnit;
                            cntr.EqptName = "40' GP";
                        } else if (cntr.EqptCode == 'D40H') {
                            this.info.q40hqqty +=1;
                            this.info.q40hqpkg += cntr.Pkg;
                            this.info.q40hqpkgunit = cntr.PkgUnit;
                            cntr.EqptName = "40' HQ";
                        } else if (cntr.EqptCode == 'D45H') {
                            this.info.q45hqqty +=1;
                            this.info.q45hqpkg += cntr.Pkg;
                            this.info.q45hqpkgunit = cntr.PkgUnit;
                            cntr.EqptName = "45' HQ";
                        }
                        cntr.markings ="";
                        cntr.descriptions ="";
                        for (var index3 in cntr.OnlineBkgMarks) {
                            cntr.markings += cntr.OnlineBkgMarks[index3].Marking + "\n";
                            cntr.descriptions += cntr.OnlineBkgMarks[index3].Description + "\n";
                        }
                        
                        if (cntr.markings != "") {
                            cntr.markings = cntr.markings.slice(0, -1);
                        }
                        if (cntr.descriptions != "") {
                            cntr.descriptions = cntr.descriptions.slice(0, -1);
                        }
                        if (cntr.OnlineBkgMarks.length > 0) {
                            if (this.markingType == "undefined") {
                            this.markingType = "single";
                            this.markings = cntr.markings;
                            this.descriptions = cntr.descriptions;
                            } else if (this.markingType == "single") {
                            this.markingType = "multi";
                            this.isShowRowSelect = true;
                            this.info.cntrs[0].isSelected = true;
                            
                            }
                        }
                        this.info.cntrs.push(cntr);
                    }
                    
                    if (this.markingType == "undefined") {
                        this.markingType = "single";
                    }
                    this.info.cntrQty = this.sumQty();

                    const formatter = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2
                    });

                    let amtMap = {};

                    for (var index4 in this.info.OnlineBkgMemos) {
                        let Chg = this.info.OnlineBkgMemos[index4];

                        if (!amtMap[Chg.IncCurrCode]) {
                            amtMap[Chg.IncCurrCode] = 0;
                        }  
                        
                        amtMap[Chg.IncCurrCode] += Chg.IncAmt;

                        if (Chg.ChgCode.trim() == 'F055') {
                            this.info.GP20TF = formatter.format(Chg.IncAmt);
                            this.info.GP20F = formatter.format(Chg.IncUnitPrice);
                            continue;
                        }

                        
                        if (Chg.ChgCode.trim() == 'F056') {
                            this.info.GP40TF = formatter.format(Chg.IncAmt);
                            this.info.GP40F = formatter.format(Chg.IncUnitPrice);
                            continue;
                        }

                        
                        if (Chg.ChgCode.trim() == 'F057') {
                            this.info.HQ40TF = formatter.format(Chg.IncAmt);
                            this.info.HQ40F = formatter.format(Chg.IncUnitPrice);
                            continue;
                        }

                        
                        if (Chg.ChgCode.trim() == 'F058') {
                            this.info.HQ45TF = formatter.format(Chg.IncAmt);
                            this.info.HQ45F = formatter.format(Chg.IncUnitPrice);
                            continue;
                        }

                        if (Chg.ChgCode.trim() == 'RATEXDIS') {
                            this.discount.DiscountCode = Chg.ChgName;
                            this.discount.NoOfUnit = Chg.NoOfUnit;
                            this.discount.Discount = formatter.format(Chg.IncAmt);
                            this.discount.CurrCode = Chg.IncCurrCode;
                            this.discount.AmountF = formatter.format(Chg.IncUnitPrice);
                            continue;
                        }
                        
                        Chg.IncUnitPriceF = formatter.format(Chg.IncUnitPrice);
                        console.log(Chg)
                        this.LocalCharges.push(Chg);
                    }

                    this.info.TotalAll = "";
                    for (let i2 in amtMap) {
                    
                        this.info.TotalAll += i2 + " " + formatter.format(amtMap[i2]) + "\n";
                    }
                    
                    console.log("markingType : " + this.markingType)
                } catch (e) {
                    console.log(e);
                }
            
            },
            async cancelBooking() {
                
                try {
                    const response = await CancelBooking(this.selectedSONo);
                    if (response.data.isSuccess) {
                        console.log('Success');   
                        this.initResult(); 
                        this.cancelDialog = false;
                    } else {

                        console.log(response.data.Msg); 
                        this.cancelDialog = false;
                    }
                    
                    console.log(response);
                } catch (e) {
                    console.log(e);
                }
            },
        
            sumQty() {
                let qty20 = (this.info.Ttl20 == null || this.info.Ttl20 == "") ? 0 : this.info.Ttl20;
                let qty40 = (this.info.Ttl40 == null || this.info.Ttl40 == "") ? 0 : this.info.TTtl40tl20;
                let qty40hq = (this.info.Ttl40Hq == null || this.info.Ttl40Hq == "") ? 0 : this.info.Ttl40Hq;
                let qty45hq = (this.info.Ttl45 == null || this.info.Ttl45 == "") ? 0 : this.info.Ttl45;
                return qty20 + qty40 + qty40hq + qty45hq;
            },
        },
        render: function ( data, type, row ) {
            console.log(row)
            return row.creditor.name + '<br>(' + row.creditor.city + ')';
        },
    }
    
</script>

<style lang="scss">
    .booking-data {
        float:left;
        text-align: left;
    }

    .headline_detail {
        color:white;
        background-color: royalblue
    }
</style>
