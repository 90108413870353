const GetPriceDetail = (result, Chg) => {
    var PriceDetail = {};
    PriceDetail.NoOfUnit = 1;
    PriceDetail.UnitPrice = UnitPrice;
    PriceDetail.Amt = 0;


    let Unit = Chg.Unit.trim().toUpperCase();
    let UnitPrice = Chg.UnitPrice;
    let MinChg = Chg.SellMin;
    if (Unit.trim() == 'BL') {
        PriceDetail.NoOfUnit = 1;
    } else if (Unit == '20') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, Unit);
    } else if (Unit == '40') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, Unit);
    } else if (Unit == '40H') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '40HQ');
    } else if (Unit == '40/40H') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '40') + getContainerQuantity(result, '40HQ');
    } else if (Unit == '45H') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '45HQ');
    } else if (Unit == '45') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '45HQ');
    } else if (Unit == '53') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, Unit);
    } else if (Unit == 'CBM') {
        PriceDetail.NoOfUnit = 1;
    } else if (Unit == 'KG') {
        PriceDetail.NoOfUnit = 1;
    } else if (Unit == 'RT') {
        PriceDetail.NoOfUnit = 1;
    } else if (Unit == 'CNTR') {
        PriceDetail.NoOfUnit = result.qty20 + result.qty40 + result.qty40hq + result.qty45hq;
    } else if (Unit == 'TEU') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '20');
    } else if (Unit == 'FEU') {
        PriceDetail.NoOfUnit = getContainerQuantity(result, '40') + getContainerQuantity(result, '40HQ') + getContainerQuantity(result, '45');
    } else if (Unit == '') {
        PriceDetail.NoOfUnit = 0;
        PriceDetail.UnitPrice = 0;
        PriceDetail.Amt = 0;

        return PriceDetail;
    }

    var Amount = PriceDetail.NoOfUnit * PriceDetail.UnitPrice;

    if (Amount < MinChg) {
        Amount = MinChg;
        PriceDetail.NoOfUnit = 1;
        PriceDetail.UnitPrice = Amount;
    }
    PriceDetail.Amt = Amount;

    return PriceDetail;
}

function getContainerQuantity(result, CntrSize) {
    var count = 0;
    if (CntrSize == '20') {
      return result.qty20;
    } else if (CntrSize == '40H' || CntrSize == '40HQ') {
      return result.qty40hq;
    } else if (CntrSize == '40') {
      return result.qty40;
    } else if (CntrSize == '45' || CntrSize == '45H' || CntrSize == '45HQ' ) {
      return result.qty45hq;
    } 


    return count;
}

export { GetPriceDetail };