<template>
    <div class="help">
        <main-menu></main-menu>
        
        <v-app id="inspire">
            <h1 class="notice">Notification</h1>
            <v-container>
                <v-card class="mx-auto" elevation="12" height="150" max-width="500">
                </v-card>
            </v-container>
        </v-app>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu},
        data: () => ({
            loading: false,
            transition: 'scale-transition'
        }),
    }
    
</script>
