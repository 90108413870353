<template>
  <div class="ratesheet" id="ratesheet">
    <main-menu></main-menu>
    <v-app id="inspire" style="background-color:#f3f3f3" >
      <v-card class="my-5 mx-auto" outlined max-width="100%" max-height="100%">
        <v-container dense >
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row no-gutters>
              <v-col class="pa-10">
                <v-row class="py-0 mx-auto">
                  <v-alert type="error" dense v-model="alert">{{alert_message}}</v-alert>
                </v-row>
                <v-row class="py-0"  v-for="oIndex in 5" :key="oIndex">
                  <v-col class="port py-0" cols="6">
                    <select-port
                      v-model="origins[oIndex]"
                      item-text="name"
                      item-value="code"
                      label="From"
                      required
                      ref="origin_port"
                      searchType="FCL"
                      :rules="portRules"
                      hide-no-data
                      prepend-icon="mdi-database-search"
                      :error-messages="portFromMessage()"
                    ></select-port>
                  </v-col>
                  <v-col class="port py-0" cols="6">
                    <select-port
                      v-model="destinations[oIndex]"
                      item-text="name"
                      item-value="code"
                      label="To"
                      required
                      ref="destination_port"
                      searchType="FCL"
                      :rules="portRules"
                      hide-no-data
                      prepend-icon="mdi-database-search"
                      :error-messages="portToMessage()"
                    ></select-port>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              
              <v-col cols="2" class="mx-auto px-auto">
                <v-card-actions>
                  
                <v-btn color="primary" dense @click="download_ratesheet">Download</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>

      <v-snackbar centered :color="message_color" v-model="snackbar">
          {{ this.message }}
          <v-btn text @click="snackbar = false" style="float:right" >
              <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
  import Vue from "vue";
  import MainMenu from "@/components/Menu";
  import SelectPort from "@/components/Select/SelectPort.vue";
  import { GenerateRateSheet } from "@/api/publicServices";
  import Axios from "axios";
  
  Vue.component("main-menu", { MainMenu });
  export default {
    components: { MainMenu, SelectPort: SelectPort },
    mounted () {
    },
    data: () => ({
      publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
      tabs: [],
      filter: null,
      valid: true,
      modal: false,
      menu2: false,
      origin: null,
      destination: null,
      
      origins: [],
      destinations: [],
      minDate: new Date().toISOString().substr(0, 10),
      portRules: [v => !!v || "This field is required"],
      lazy: false,
      port_from_message: "",
      port_to_message: "",
      alert_message:"",
      alert:false,
      snackbar: false,
      message:"",
      message_color:"primary",
      searchParams: {},
      dialog: false,
    }),
    methods: {
      clearPorts() {
          //console.log('reset')
          this.origin = null ;
          this.destination = null;
          this.$refs.destination_port.clearPorts();
          this.$refs.origin_port.clearPorts();
          return;
        
      },
      portFromMessage() {
        return this.port_from_message;
      },
      portToMessage() {
        return this.port_to_message;
      },
      async download_ratesheet() {
        let originList = [];
        let destinationList = [];

        for (let index in this.origins) {
          let ori = this.origins[index];
            if (ori != null && ori.PortCode != '') {
              originList.push(ori.PortCode);
            }
        }
        for (let index in this.destinations) {
            let dest = this.destinations[index];
            if (dest != null && dest.PortCode != '') {
              destinationList.push(dest.PortCode);
            }
        }
        
        console.log(originList);
        console.log(destinationList);
        try {
          const response = await GenerateRateSheet(originList, destinationList);
          console.log(response);

          if (response.data.isSuccess) {

            this.downloadRateSheet(response.data.Key);
          }

        } catch (error) {
          console.log(error);
        }
        return;
      },
      async downloadRateSheet(ID) {
 
            let baseURL = process.env.NODE_ENV === 'production'
            ? window.location.origin + '/DtsService'
            : "http://localhost/DtsService";
            const url = baseURL + '/BookingApi/DownloadRateSheet/' + ID;
              
            Axios.get(url, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'RateSheet'
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
      }
    }
  };

</script>

