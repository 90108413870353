<template>
    <div class="tracking">
        <main-menu></main-menu>
        <h1 class="notice">Tracking</h1>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu}
    }
    
</script>