<template>
    <div class="personal">
        <main-menu></main-menu>
        
        <v-app id="inspire" style="background-color:#f3f3f3" >
            <v-card outlined min-height="500px">
                <v-toolbar flat  color="#92D050" >
                    <v-toolbar-title >Setting</v-toolbar-title>
                </v-toolbar>
                <v-tabs vertical color="#529050">
                    <v-tab >
                        <v-row >
                            <v-col cols=2>
                                <v-icon left>mdi-account</v-icon>
                                Account Setting
                            </v-col>
                        </v-row>
                    </v-tab>
                    <v-tab>
                        <v-row>
                            <v-col cols=2>
                                <v-icon left>mdi-account-plus</v-icon>
                                Create User
                            </v-col>
                        </v-row>
                    
                    </v-tab>
            
                    <v-tab-item >
                        <v-card>
                            <v-row>
                                <v-col>
                                    <v-card-title>Change Password</v-card-title>
                                </v-col>
                            </v-row>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field type="password" label="Current Password" v-model="currentPassword" >
                                        </v-text-field>
                                    </v-col>
                                </v-row> 
                                <v-divider></v-divider>
                                <v-row>
                                    <v-col cols="2" >
                                        <v-text-field type="password" label="New Password" v-model="newPassword" >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row >
                                    <v-col cols="2">
                                        <v-text-field type="password" label="Comfirm Password" v-model="confirmPassword">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row >
                                    <v-col cols="2">
                                        <v-btn tile large color="#92D050" dark class="mx-auto mb-10 btn" @click="savePassword">
                                            <v-icon >mdi-floppy</v-icon> Save
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            
                        </v-card>
                        
                    </v-tab-item>
                    <v-tab-item>
                        <v-card class="">
                            
                            <v-row>
                                <v-col>
                                    <v-card-title>Create New User</v-card-title>
                                </v-col>
                            </v-row>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field label="Email" v-model="email" >
                                        </v-text-field>
                                    </v-col>
                                </v-row> 
                                <v-row>
                                    <v-col cols="2" >
                                        <v-text-field type="password" label="Password" v-model="password" >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row >
                                    <v-col cols="2">
                                        <v-btn tile large color="#92D050" dark class="mx-auto mb-10 btn" @click="createUser">
                                            OK
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
                
                <v-row justify="space-around"> 
                    <v-snackbar centered :color="messageColor" v-model="messageSnackbar" >
                        {{ this.message }}
                        <v-btn text @click="messageSnackbar = false" style="float:right" >
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-row>
            </v-card>
        </v-app>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    import { CreateUser, ChangePassword } from "@/api/publicServices";
    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu},
        
        data: () => ({
            currentPassword:"",
            newPassword:"",
            confirmPassword:"",
            email:"",
            password:"",
            messageSnackbar: false,
            message:"",
            messageColor:"error",
        }),
        methods : {
            async savePassword() {
                
                if (this.newPassword != this.confirmPassword) {
                    this.messageSnackbar = true;
                    this.messageColor = "error";
                    this.message = "Confirm password is not matched.";
                }

                try {
                    let UserID = localStorage.getItem('userid');
                    const response = await ChangePassword(UserID, this.currentPassword, this.newPassword);
                    if (response.data.isSuccess) {
                        this.message = "Password is changed.";
                        this.messageColor = "success";
                        this.messageSnackbar = true;
                    } else {
                        this.messageSnackbar = true;
                        this.messageColor = "error";
                        if (response.data.Msg == "NOT_MATCH") {
                            this.message = "Password is incorrect.";
                        } else {
                            this.message = "System Fail. Please try again later.";
                        }
                    }
                } catch (e) {
                    console.log(e);    
                    return;
                }
            },
            async createUser() {

                if (this.email == "") {
                    
                    this.messageSnackbar = true;
                    this.messageColor = "error";
                    this.message = "Please input email";
                }

                
                if (this.password == "") {
                    
                    this.messageSnackbar = true;
                    this.messageColor = "error";
                    this.message = "Please input password";
                }

                try {
                    let UserID = localStorage.getItem('userid');
                    const response = await CreateUser(UserID, this.email, this.password);
                    if (response.data.isSuccess) {
                        this.message = "User is created.";
                        this.messageSnackbar = true;
                        this.messageColor = "success";
                    } else {
                        this.messageSnackbar = true;
                        this.messageColor = "error";
                        if (response.data.Msg == "EXISTED") {
                            this.message = "User is already existed.";
                        } else {
                            this.message = "System Fail. Please try again later.";
                        }
                    }
                } catch (e) {
                    console.log(e);
                    return;
                }
            
            }
        }
    }
    
</script>



<style scoped>
    .btn {
        font-family: 'Century Gothic';
    }

</style>