<template>
    <div class="document">
        <main-menu></main-menu>
        
        <v-app id="inspire">
            <h1 class="notice">There are no B/L Instructions yet</h1>
            <h2 class="notice">Once a booking is confirmed, a B/L Instruction record will be created here.</h2>
        </v-app>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu}
    }
    
</script>