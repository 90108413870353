<template>
  <v-autocomplete
    v-model="country"
    :items="countryOptions"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    auto-select-first
    item-text="CountryName"
    item-value="CountryCode"
    :label="label"
    prepend-icon="mdi-database-search"
    return-object no-filter
    :error-messages="errorMessages"
  ></v-autocomplete>
</template>

<script>
import _ from "lodash";
import { SelectCountryApi } from "@/api/publicServices";
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    cachedCountrys: {
      type: Array
    },
    errorMessages: {
      type:String 
    }
  },
  data: () => ({
    countryOptions: [],
    isLoading: false,
    search: null,
    country: null
  }),

  computed: {},
  created: function() {
    this.debouncedSearchCountry = _.debounce(this.searchCountry, 500);
    this.debouncedFilterCachedCountry = _.debounce(this.filterCachedCountrys, 500);
  },
  methods: {
    async searchCountry(searchVal) {
      if (searchVal == null) return; 
      if (this.isLoading) return;
      if (searchVal.length === 0) return;
      if (!_.isEmpty(this.country) && this.country.CountryName === searchVal) return;
      this.isLoading = true;
      try {
        const response = await SelectCountryApi(searchVal);
        this.isLoading = false;
        this.countryOptions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    filterCachedCountrys(searchVal) {
      if (this.isLoading) return;
      if (searchVal.length === 0) return;
      const searchText = searchVal.toLowerCase();
      this.countryOptions = this.cachedCountrys.filter(country => {
        return country.CountryName.toLowerCase().indexOf(searchText) > -1;
      });
      this.isLoading = false;
    }
  },
  watch: {
    search(newVal) {
      if (this.cachedCountrys && this.cachedCountrys.length > 0) {
        this.debouncedFilterCachedCountry(newVal);
      } else {
        this.debouncedSearchCountry(newVal);
      }
    },
    country() {
      this.$emit("input", this.country);
    }
  }
};
</script>