import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import QuotesSearch from '@/components/QuotesSearch.vue';
import Booking from '@/components/Booking.vue';
import Quotes from '@/components/Quotes.vue';
import BookingForm from '@/components/BookingForm.vue';
import BookingFormGuest from '@/components/BookingFormGuest.vue';
import UpdateBooking from '@/components/UpdateBooking.vue';
import Notification from '@/components/Notification.vue';
import Document from '@/components/Document.vue';
import Tracking from '@/components/Tracking.vue';
import Personal from '@/components/Personal.vue';
import Agent from '@/components/Agent.vue';
import RateSheet from '@/components/RateSheet.vue';

Vue.use(Router)

let router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
        path: '/',
        name: 'login',
        component: Login,
          meta: {
              guest: true
          }
        },
        {
        path: '/register',
        name: 'register',
        component: Register,
          meta: {
              guest: true
          }
        },
        {
        path: '/search_quotes',
        name: 'search_quotes',
        component: QuotesSearch,
          meta: {
              guest: true
          }
        },
        { 
            path: '/home',
            name: 'home',
            component: Quotes,
            meta: {
              requiresAuth: true,
              is_user: true
            }
        },
        { 
            path: '/booking_form',
            name: 'booking_form',
            component: BookingForm,
            meta: {
              requiresAuth: true,
              is_user: true
            }
        },
        { 
            path: '/booking_form_guest',
            name: 'booking_form_guest',
            component: BookingFormGuest,
            meta: {
                guest:true
            }
        },
        { 
            path: '/update_booking',
            name: 'update_booking',
            component: UpdateBooking,
            meta: {
              requiresAuth: true,
              is_user: true
            }
        },
        { 
            path: '/booking',
            name: 'booking',
            component: Booking,
            meta: {
              requiresAuth: true,
              is_user: true
            }
        },
        { 
            path: '/quotes',
            name: 'quotes',
            component: Quotes,
            meta: {
                requiresAuth: true,
                is_user: true
            }
        },
        { 
            path: '/agent',
            name: 'agent',
            component: Agent,
            meta: {
                requiresAuth: true,
                is_agent: true
            }
        },
        { 
            path: '/notification',
            name: 'notification',
            component: Notification,
            meta: {
                requiresAuth: true,
                is_agent: false,
                is_user: true
            }
        },
        { 
            path: '/document',
            name: 'document',
            component: Document,
            meta: {
                requiresAuth: true
            }
        },
        { 
            path: '/tracking',
            name: 'tracking',
            component: Tracking,
            meta: {
                requiresAuth: true
            }
        },
        { 
            path: '/personal',
            name: 'personal',
            component: Personal,
            meta: {
                requiresAuth: true,
                is_agent: true,
                is_user: true
            }
        },
        { 
            path: '/ratesheet',
            name: 'ratesheet',
            component: RateSheet,
            meta: {
                requiresAuth: true,
                is_agent: true,
                is_user: true
            }
        }
    ]

});

router.beforeEach((to, from, next) => {
    document.title = '鸿安货运电商平台';
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null) {
            next("/")
        } else {
            let userType = localStorage.getItem('user_type');
            let isAgent = to.matched.some(record => record.meta.is_agent);
            let isUSer = to.matched.some(record => record.meta.is_user);
            
            if (userType == "AGENT") {
                if (!isAgent) {
                    next({ name: 'agent'});
                } else {
                    next();
                }
            } else {
                if (!isUSer) {
                    next({ name: '/'});
                } else {
                    next();
                }
            } 
        }
    } else {
        next()
    }
});

export default router;

