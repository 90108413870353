<template>
    <v-app id="register">
        <div id="main-menu" >
            <v-card color="grey lighten-4" flat class="mx-auto">
                <v-toolbar dark class="menu">
                    <v-toolbar-title><v-img src="@/assets/logo_ratex_dark.png"></v-img></v-toolbar-title>
                    
                    <v-spacer></v-spacer>
                    <v-btn icon to="/home" class="mx-auto">
                        <v-icon>mdi-home</v-icon> Home
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon to="/search_quotes">
                        <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon to="/register">
                        <v-icon>mdi-account</v-icon> Register
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
                
                <v-spacer background-color="lightblue"></v-spacer>
            </v-card>
            
        </div>




        <v-stepper v-model="bookingForm">
                <v-stepper-header>
                    <v-stepper-step :complete="bookingForm > 1" step="1">Booking Parties</v-stepper-step>
            
                    <v-divider></v-divider>
            
                    <v-stepper-step :complete="bookingForm > 2" step="2">Containers</v-stepper-step>
            
                    <v-divider></v-divider>
            
                    <v-stepper-step :complete="bookingForm > 3" step="3">Charges</v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step :complete="bookingForm > 4" step="4">Cargo</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="5">Confirm</v-stepper-step>
                </v-stepper-header>
  
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12" flat>
                  <v-card class="party" >
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Booking Party</v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.ActShName"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.ActShTel"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col >
                        <v-text-field label="Your Reference" hide-details="auto" v-model="info.AgtRefNo"></v-text-field>  
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-sh" v-show="showSH===true">
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Shipper
                        <v-btn icon color="pink" @click="showSH=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.ShName"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.ShTel"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.ShAdd"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-cn" v-show="showCN===true">     
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Consignee
                        <v-btn icon color="pink" @click="showCN=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.CnName"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.CnTel"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.CnAdd"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-np" v-show="showNP===true">
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Notify Party
                        <v-btn icon color="pink" @click="showNP=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.NpName"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.NpTel"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.NpAdd"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row>
                    <v-col>
                      <v-card-text v-show="showSH===false">
                        <v-btn icon color="pink" @click="showSH=true" class="show-sh">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Shipper
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text v-show="showCN===false">
                        <v-btn icon color="pink" @click="showCN=true" class="show-cn">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Consignee
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text v-show="showNP===false">
                        <v-btn icon color="pink" @click="showNP=true" class="show-np">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Notify Party
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
                <v-row justify="space-around"> 
                  <v-btn color="primary" >
                    Back
                  </v-btn>
                  <v-btn color="primary" @click="bookingForm = 2" >
                    Next
                  </v-btn>
                </v-row>
              </v-stepper-content>
        
              <v-stepper-content step="2">
                <v-card flat v-show="info.qty20 > 0 && info.GP20 > 0" class="mx-auto" max-width="500px" >
                  <v-card-subtitle>
                    20' GP X {{info.qty20}}
                    <v-btn icon @click="info.qty20=info.qty20+1" color="pink"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn icon @click="info.qty20=info.qty20-1" color="pink"><v-icon>mdi-minus</v-icon></v-btn>
                  </v-card-subtitle>
                  <v-row justify="center">
                    <v-col >
                      <v-text-field 
                      v-model="info.q20pkg" :rules="pkgRules" label="Weight"></v-text-field>
                    </v-col>
                    <v-col >
                      <v-autocomplete
                      v-model="info.q20pkgunit"
                      :items="pkgUnit"
                      label="Package Unit"
                      auto-select-first
                      return-object
                      outlined
                    ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card flat v-show="info.qty40 > 0 && info.GP40 > 0" class="mx-auto" max-width="500px" >
                  <v-card-subtitle>
                  40' GP X {{info.qty40}}
                    <v-btn icon @click="info.qty40=info.qty40+1" color="pink"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn icon @click="info.qty40=info.qty40-1" color="pink"><v-icon>mdi-minus</v-icon></v-btn>
                  </v-card-subtitle>
                  <v-row>
                    <v-col>
                      <v-text-field 
                      v-model="info.q40pkg" :rules="pkgRules" label="Weight"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                      v-model="info.q40pkgunit"
                      :items="pkgUnit"
                      label="Package Unit"
                      auto-select-first
                      return-object
                      outlined
                    ></v-autocomplete>
                    </v-col>
                  </v-row>
                
                </v-card>
                <v-card flat v-show="info.qty40hq > 0 && info.HQ40 > 0" class="mx-auto" max-width="500px" >
                  <v-card-subtitle>
                    40' HQ X {{info.qty40hq}}
                    <v-btn icon @click="info.qty40hq=info.qty40hq+1" color="pink"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn icon @click="info.qty40hq=info.qty40hq-1" color="pink"><v-icon>mdi-minus</v-icon></v-btn>
                  </v-card-subtitle>
                  <v-row>
                    <v-col >
                      <v-text-field 
                      v-model="info.q40hqpkg" :rules="pkgRules" label="Weight"></v-text-field>
                    </v-col>
                    <v-col >
                      <v-autocomplete
                      v-model="info.q40hqpkgunit"
                      :items="pkgUnit"
                      label="Package Unit"
                      auto-select-first
                      return-object
                      outlined
                    ></v-autocomplete>
                    </v-col>
                  </v-row>
                
                </v-card>
                <v-card flat v-show="info.qty45hq > 0 && info.HQ45 > 0" class="mx-auto" max-width="500px" >
                  <v-card-subtitle>
                    45' HQ X {{info.qty45hq}}
                    <v-btn icon @click="info.qty45hq=info.qty45hq+1" color="pink"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn icon @click="info.qty45hq=info.qty45hq-1" color="pink"><v-icon>mdi-minus</v-icon></v-btn>
                  </v-card-subtitle>
                  <v-row>
                    <v-col>
                      <v-text-field 
                      v-model="info.q45hqpkg" :rules="pkgRules" label="Weight"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                      v-model="info.q45hqpkgunit"
                      :items="pkgUnit"
                      label="Package Unit"
                      auto-select-first
                      return-object
                      outlined
                    ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>

                
                <v-row>
                  <v-col>
                    <v-card-text v-show="info.qty20==0">
                      <v-btn icon color="pink" @click="info.qty20=info.qty20+1">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>20' GP
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-text v-show="info.qty40==0">
                      <v-btn icon color="pink" @click="info.qty40=info.qty40+1" >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>40' GP
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-text v-show="info.qty40hq==0">
                      <v-btn icon color="pink" @click="info.qty40hq=info.qty40hq+1">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>40' HQ
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-text v-show="info.qty45hq==0">
                      <v-btn icon color="pink" @click="info.qty45hq=info.qty45hq+1">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>45' HQ
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row justify="space-around"> 
                  <v-snackbar centered color="error" v-model="pkgSnackbar">
                      {{ this.pkgMessage }}
                      <v-btn text @click="pkgSnackbar = false" style="float:right" >
                          <v-icon small>mdi-close</v-icon>
                      </v-btn>
                  </v-snackbar>
                </v-row>
                <v-row justify="space-around"> 
                  <v-btn
                    color="primary"
                    @click="bookingForm = 1">
                    Previous
                  </v-btn>  
                  <v-btn
                    color="primary"
                    @click="goto3">
                    Next
                  </v-btn>
                </v-row>
        
              </v-stepper-content>
        
              <v-stepper-content step="3">
                <v-card class="mx-auto mb-12" flat max-width="600px" min-height="400px" >
                  <v-row>
                    <v-col cols=4>
                        Ocean Freight
                    </v-col>
                    <v-col cols=2>
                      <input type="radio" v-model="info.FrtType" name="FrtType" value="PREPAID" checked /> Prepaid
                    </v-col>
                    <v-col cols=2>
                      <input type="radio" v-model="info.FrtType" name="FrtType" value="COLLECT" /> Collect
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols=4>
                        Local Charge
                    </v-col>
                    <v-col cols=2>
                      <input type="radio" v-model="info.OthChgType" name="OthChgType" value="PREPAID" checked /> Prepaid
                    </v-col>
                    <v-col cols=2>
                      <input type="radio" v-model="info.OthChgType" name="OthChgType"  value="COLLECT" /> Collect
                    </v-col>
                  </v-row>

                </v-card>
                <v-row justify="space-around"> 
                  <v-btn
                    color="primary"
                    @click="bookingForm = 2">
                    Previous
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="goto4">
                    Next
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="4">
                
                <v-card flat class="mx-auto" max-width="500px" min-height="400px">
                  <v-card-text>
                    Commodity
                  </v-card-text>
                  <v-row justify="center" v-for="cargo in info.cargos" :key="cargo.index">
                    <v-col cols=6>
                      <v-text-field v-model="cargo.commodity" :rules="emptyRules" label="Commodity" outlined></v-text-field>
                    </v-col>
                    <v-col cols=5>
                      <v-text-field v-model="cargo.hscode" label="HSCode" outlined></v-text-field>
                    </v-col>
                    
                    <v-col cols=1 >
                      
                      <v-btn icon @click="deleteCargo(cargo.index)">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn icon @click="addCargo()">
                        <v-icon color="pink">mdi-plus</v-icon>
                      </v-btn>
                      Add Cargo
                    </v-col>
                  </v-row>
                </v-card>
                
                <v-row justify="space-around"> 
                  <v-snackbar centered color="error" v-model="cargoSnackbar">
                      {{ this.cargoMessage }}
                      <v-btn text @click="cargoSnackbar = false" style="float:right" >
                          <v-icon small>mdi-close</v-icon>
                      </v-btn>
                  </v-snackbar>
                </v-row>
                <v-row justify="space-around"> 
                  <v-btn
                    color="primary"
                    @click="bookingForm = 3">
                    Previous
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="goto5">
                    Next
                  </v-btn>
                </v-row>
              </v-stepper-content>
                  
              <v-stepper-content step="5">
                <v-card class="mx-auto mb-12" flat max-width="820px" >
                  <v-card class="mb-5 py-5">
                      <table style="width:100%">
                          <tr>
                            <td cols="2">
                              <v-card-subtitle class="py-0 detail-title">Carrier</v-card-subtitle>
                            </td>
                            <td cols="3">
                              <v-card-subtitle class="py-0 detail-title">ETD</v-card-subtitle>
                            </td>
                            <td cols="2">
                              <v-card-subtitle class="py-0 detail-title">TT</v-card-subtitle>
                            </td>
                            <td cols="3">
                              <v-card-subtitle class="py-0 detail-title">ETA</v-card-subtitle>
                            </td>
                            <td cols="2">
                              <v-card-subtitle class="py-0 detail-title">Vessel Voyage</v-card-subtitle>
                            </td>
                          </tr>
                          <tr no-gutters>
                            
                            <td cols="2" rowspan="2">
                              <v-img contain :aspect-ratio="1" width="150px" height="60px" :src="`${publicPath}images/pr/${this.info.PrCode}.png`">
                              </v-img>  
                            </td>
                            
                            <td cols="3">
                              <v-card-text class="py-0">{{this.info.ETD}}</v-card-text>
                            </td>

                            <td cols="2">
                              <v-card-text class="py-0">{{this.info.TT}} Days</v-card-text>
                            </td>
                            <td cols="3">
                              <v-card-text class="py-0">{{this.info.ETA}}</v-card-text>
                            </td>
                            <td cols="2">
                              <v-card-text class="py-0">{{this.info.VslName + " " + this.info.Voy}}</v-card-text>
                            </td>
                          </tr>
                          <tr no-gutters>
                            <td cols="3">
                              <v-card-text class="py-1">{{this.info.PolName}}</v-card-text>
                            </td>
                            <td cols="2">&nbsp;</td>
                            <td>
                              <v-card-text class="py-1">{{this.info.PldName}}</v-card-text>
                            </td>
                            
                          </tr>
                        </table>
                  </v-card>

                  <v-card class="mb-5">
                    <v-row>
                      <v-col>
                        <v-card-title>Booking Party</v-card-title>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Name</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ActShName}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Tel</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ActShTel}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Address</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ActShAdd}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Reference</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.AgtRefNo}}</v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                
                  <v-card class="mb-5 shipper-info" v-show="info.ShName != ''">
                    <v-row>
                      <v-col>
                        <v-card-title>Shipper</v-card-title>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Name</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ShName}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Tel</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ShTel}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Address</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.ShAdd}}</v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                  
                  <v-card class="mb-5 consignee-info" v-show="info.CnName != ''">
                    <v-row>
                      <v-col>
                        <v-card-title>Consignee</v-card-title>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-text>{{info.CnName}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Tel</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.CnTel}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Address</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.CnAdd}}</v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card class="mb-5 notify-party-info" v-show="info.NpName != ''">
                    <v-row>
                      <v-col>
                        <v-card-title>Notify Party</v-card-title>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Name</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.NpName}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Tel</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.NpTel}}</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-card-subtitle>Address</v-card-subtitle>
                      </v-col>
                      <v-col cols="10">
                        <v-card-text>{{info.NpAdd}}</v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card class="mb-5">
                    <v-row>
                      <v-col>
                        <v-card-title>
                          Containers
                        </v-card-title>
                      </v-col>
                    </v-row>
                    
                    <v-row v-show="this.info.q20pkg > 0" class="mx-12">
                        <v-col >
                          <v-card-subtitle>20' GP X {{this.info.qty20}}</v-card-subtitle>
                        </v-col>
                        <v-col >
                          <v-card-text>{{this.info.q20pkg}} {{this.info.q20pkgunit}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>@USD {{this.info.GP20F}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>USD {{this.info.GP20TF}} </v-card-text>
                        </v-col>
                    </v-row>

                    <v-row v-show="this.info.q40pkg > 0" class="mx-12">
                        <v-col >
                          <v-card-subtitle>40' GP X {{this.info.qty40}}</v-card-subtitle>
                        </v-col>
                        <v-col >
                          <v-card-text>{{this.info.q40pkg}} {{this.info.q40pkgunit}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>@USD {{this.info.GP40F}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>USD {{this.info.GP40TF}} </v-card-text>
                        </v-col>
                    </v-row>
                    
                    <v-row v-show="this.info.q40hqpkg > 0" class="mx-12">
                        <v-col >
                          <v-card-subtitle>40' HQ X {{this.info.qty40hq}}</v-card-subtitle>
                        </v-col>
                        <v-col >
                          <v-card-text>{{this.info.q40hqpkg}} {{this.info.q40hqpkgunit}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>@USD {{this.info.HQ40F}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>USD {{this.info.HQ40TF}} </v-card-text>
                        </v-col>
                    </v-row>

                    
                    <v-row v-show="this.info.q45hqpkg > 0" class="mx-12">
                        <v-col >
                          <v-card-subtitle>45' HQ X {{this.info.q45hqpkg}}</v-card-subtitle>
                        </v-col>
                        <v-col >
                          <v-card-text>{{this.info.q45hqpkg}} {{this.info.q45hqpkgunit}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>@USD {{this.info.HQ45F}} </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-text>USD {{this.info.HQ45TF}} </v-card-text>
                        </v-col>
                    </v-row>
                    <v-card flat v-show="this.info.Discount != ''">
                      <v-row class="mx-12">
                        <v-col>
                          <v-card-subtitle>Discount X {{sumQty()}}</v-card-subtitle>
                        </v-col>
                        <v-col>
                          <v-card-text>{{discount.DiscountCode}}</v-card-text>
                        </v-col>
                        <v-col>
                            <v-card-text>@{{discount.CurrCode}} {{discount.AmountF}}</v-card-text>
                        </v-col>
                        <v-col>
                            <v-card-text>{{this.info.Discount}}</v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card class='ma-5 pa-5' v-show="info.LocalCharges != null && info.LocalCharges.length > 0">
                      <v-card-title>Local Charges</v-card-title>
                      <v-row no-gutters v-for="LocalCharge in info.LocalCharges" :key="LocalCharge.ChgID">
                        <v-col cols=6 class='mx-5'>
                            <small>{{LocalCharge.ChgName}}</small>
                        </v-col>
                        <v-col cols=1>
                            <small>{{LocalCharge.SellCurrCode}}</small>
                        </v-col>
                        <v-col cols=4>
                            <small>{{LocalCharge.SellUnitPriceF}} X {{LocalCharge.NoOfUnit}}</small>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="mx-12">
                        <v-col >
                          <v-card-subtitle>Total</v-card-subtitle>
                        </v-col>
                        <v-col >
                          &nbsp;
                        </v-col>
                        <v-col>
                          &nbsp;
                        </v-col>
                        <v-col>
                          <v-card-text><pre>{{this.info.TotalAll}}</pre> </v-card-text>
                        </v-col>
                    </v-row>
                    
                  </v-card>
                  
                  <v-card class="mb-5">
                    <v-row>
                      <v-col>
                        <v-card-title>
                          Charges
                        </v-card-title>
                      </v-col>
                    </v-row>
                    
                    <v-row class="mx-12">
                        <v-col cols="5">
                          <v-card-text>Ocean Freight</v-card-text>
                        </v-col>
                        <v-col cols="7">
                          <v-card-text>{{info.FrtType}}</v-card-text>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mx-12">
                        <v-col cols="5">
                          <v-card-text>Local Charge</v-card-text>
                        </v-col>
                        <v-col cols="7">
                          <v-card-text>{{info.OthChgType}}</v-card-text>
                        </v-col>
                    </v-row>
                  </v-card>

                  <v-card class="mb-5">
                    <v-row>
                      <v-col>
                        <v-card-title>
                          Cargo
                        </v-card-title>
                      </v-col>
                    </v-row>
                    
                    <v-row v-for="cargo in this.info.cargos" :key="cargo.commodity" class="mx-12">
                        <v-col cols="5">
                          <v-card-text>{{cargo.commodity}} </v-card-text>
                        </v-col>
                        <v-col cols="7">
                          <v-card-text>{{cargo.hscode}} </v-card-text>
                        </v-col>
                    </v-row>
                  </v-card>
                </v-card>
                
                <v-row justify="space-around"> 
                  <v-btn
                    color="primary"
                    @click="bookingForm = 4">
                    Previous
                  </v-btn>
                  <v-btn
                    :disabled="isConfirm"
                    color="primary"
                    @click="confirmBooking">
                    Confirm
                    <v-progress-circular v-show="isConfirm"></v-progress-circular>
                  </v-btn>
                </v-row>
                
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

    </v-app>
</template>

<script>
    import { GetUserInfo, GetPricing, CreateBooking, GetLocalCharges, GetDiscount } from "@/api/publicServices";
    import { GetPriceDetail } from "@/api/common";

    export default {
        props : ["nextUrl"],
        mounted () {
          console.log(this.$route);
            this.info.qty20 = this.$route.query.q20;
            this.info.qty40 = this.$route.query.q40;
            this.info.qty40hq = this.$route.query.q40hq;
            this.info.qty45hq = this.$route.query.q45hq;
            this.info.SeaType = this.$route.query.SeaType;
            this.info.SpaceID = this.$route.query.SpaceID;
            this.info.DiscountCode = this.$route.query.discount;


            if (this.info.qty20 == null) {
              this.info.qty20 = 0;
            }
            if (this.info.qty40 == null) {
              this.info.qty40 = 0;
            }
            if (this.info.qty40hq == null) {
              this.info.qty40hq = 0;
            }
            if (this.info.qty45hq == null) {
              this.info.qty45hq = 0;
            }

            this.info.qty20 = parseInt(this.info.qty20);
            this.info.qty40 = parseInt(this.info.qty40);
            this.info.qty40hq = parseInt(this.info.qty40hq);
            this.info.qty45hq = parseInt(this.info.qty45hq);

            this.loadPricingDetail();
            this.loadInfo();
            
            this.getDiscount();
        },
        data: () => ({
          publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
          emptyRules: [
            value => !!value || 'Required.'
          ],
          emailRules: [
            value => !!value || 'Required.',
            value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
            },
          ],
          pkgRules: [
            value => {
              const pattern = /^\d+(\.\d+)*$/
              return pattern.test(value) || 'Must be a number'
            },
            value => value != 0 || 'Weight cannot be 0.'
          ],
          showSH: false,
          showCN: false,
          showNP: false,
          showq20: false,
          showq40: false,
          showq40h: false,
          showq45h: false,
          bookingForm:null,
          pkgUnit: getPkgUnit(),
          info : {
            ActShName :"",
            ActShTel :"",
            ActShAdd :"",
            AgtRefNo: "",
            ShName :"",
            ShTel :"",
            ShAdd :"",
            CnName :"",
            CnTel :"",
            CnAdd :"",
            NpName :"",
            NpTel :"",
            NpAdd :"",
            PrCode:"APLU",
            ETA:"",
            ETD:"",
            VslName:"",
            Voy:"",
            PldName:"",
            PodName:"",
            PolName:"",
            PolPodTT:"",
            qty20: 0,
            q20pkg : 0,
            q20pkgunit : 'KG',
            qty40: 0,
            q40pkg : 0,
            q40pkgunit : 'KG',
            qty40hq: 0,
            q40hqpkg : 0,
            q40hqpkgunit : 'KG',
            qty45hq: 0,
            q45hqpkg : 0,
            q45hqpkgunit : 'KG',
            SeaType: "",
            OthChgType:"PREPAID",
            FrtType:"PREPAID",
            cargos: [{ index: Date.now(), commodity:"", hscode:""}],
            DiscountCode:"",
            Discount:""
          },
          LocalCharges:[],
          pkgSnackbar:false,
          pkgMessage:"",
          cargoSnackbar:false,
          cargoMessage:"",
          isConfirm: false,
          discount: { CurrCode:"", DiscountCode:"", Amount:0}
        }),
        methods : {
            
          sumQty() {
            return this.info.qty20 + this.info.qty40 + this.info.qty40hq + this.info.qty45hq;
          },
          async getDiscount() {
            if (this.info.DiscountCode == null || this.info.DiscountCode == "") {
              return;
            }
            let UserID = localStorage.getItem('userid');

            if (UserID == null) {
                return;
            }
            try {
              const response = await GetDiscount(UserID, this.info.DiscountCode);

              if (response.data != null) {
                
                const formatter = new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2
                });
                
                this.discount = response.data;


                this.discount.AmountF = formatter.format(this.discount.Amount);
                this.info.Discount = this.discount.CurrCode + " -" + formatter.format(this.discount.Amount * this.sumQty());
              }
            } catch (error) {
              console.log(error);
            }

          },
          async getLocalCharges() {
            
              console.log(this.info)
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2
            });
            try {
              const response = await GetLocalCharges(this.info.SpaceID);
              let LocalCharges = response.data;
              this.info.LocalCharges = []; 
              let amtMap = {};
              
              amtMap["USD"] = this.info.Total;
              for (var index in LocalCharges) {
                let LocalCharge = LocalCharges[index];
                LocalCharge.NoOfUnit = GetPriceDetail(this.info, LocalCharge).NoOfUnit;
                
                if (LocalCharge.NoOfUnit == null ||LocalCharge.NoOfUnit == "" || LocalCharge.NoOfUnit == 0) {
                  continue;
                }
                
                if (!amtMap[LocalCharge.SellCurrCode]) {
                  amtMap[LocalCharge.SellCurrCode] = 0;
                }  
                amtMap[LocalCharge.SellCurrCode] += LocalCharge.SellUnitPrice * LocalCharge.NoOfUnit;
                
                LocalCharge.SellUnitPriceF = formatter.format(LocalCharge.SellUnitPrice);
                this.info.LocalCharges.push(LocalCharge);
              }


              
              if (this.info.Discount != '') {
                let amt = this.discount.Amount * this.sumQty() * -1;
                

                if (amt != 0) {
                  if (!amtMap[this.discount.CurrCode]) {
                    amtMap[this.discount.CurrCode] = 0;
                  }
                  amtMap[this.discount.CurrCode] += amt;
                }
              }


              this.info.TotalAll = "";
              for (let i2 in amtMap) {
              
                this.info.TotalAll += i2 + " " + formatter.format(amtMap[i2]) + "\n";
              }
              
            } catch (error) {
              console.log(error);
            }
          },
          goto3() {
              this.calTotal();
              if (this.info.q20pkg == 0 && this.info.q40pkg == 0 && this.info.q40hqpkg == 0 && this.info.q45hqpkg == 0) {
                this.pkgSnackbar = true;
                this.pkgMessage = "Weight cannot be 0";
                return;
              }
              this.getLocalCharges();
              this.bookingForm = 3
          },
          goto4() {
              this.bookingForm = 4;
          },
          goto5() {
              if (this.info.cargos.length == 0) {
                this.cargoSnackbar = true;
                this.cargoMessage = "At least one cargo";
                return;
              }
              for (var index in this.info.cargos) {
                let cargo = this.info.cargos[index];
                if (cargo.commodity == "" || cargo.hscode == "") {
                  this.cargoSnackbar = true;
                  this.cargoMessage = "Commodity or HSCode cannot be empty";
                  return;
                }
              }
              
              this.bookingForm = 5;
          },
          calTotal() {
            
              const formatter = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2
              });
              let total20 = this.info.GP20 * this.info.qty20;
              let total40 = this.info.GP40 * this.info.qty40;
              let total40hq = this.info.HQ40 * this.info.qty40hq;
              let total45hq = this.info.HQ45 * this.info.qty45hq;
              
              this.info.GP20TF = formatter.format(total20);
              this.info.GP40TF = formatter.format(total40);
              this.info.HQ40TF = formatter.format(total40hq);
              this.info.HQ45TF = formatter.format(total45hq);

              this.info.Total = total20 + total40 + total40hq + total45hq;
              this.info.TotalF = formatter.format(this.info.Total);
          },
          async confirmBooking() {
            this.isConfirm = true;
            try {

              for (let index in this.info.cargos) {
                this.info.cargos[index].index = index;
              }
              const response = await CreateBooking(this.info);

              if (response.data.isSuccess) {
                this.$router.push({name:'search_quotes'}).catch(() => {});
              } else {
                alert('Unexpected error. ' + response.data.Msg);
                this.isConfirm = false;
                return;
              }
            } catch (e) {
              console.log(e);
              return;
            }
          },
          addCargo() {
            this.info.cargos.push( {index: Date.now(), commodity:"", hscode: ""});
          },
          deleteCargo(cargoIndex) {
            for (var index in this.info.cargos) {
              var cargo = this.info.cargos[index];
              if (cargo.index === cargoIndex) { 
                this.info.cargos.splice(index, 1);
                break;
              }
            }
          }, 
          getQty(qty) {
            if (this.$route.params[qty]) {
              return this.$route.params[qty];
            } else {
              return 0;
            }
          },
          async loadInfo() {
            
            let UserID = localStorage.getItem('userid');
            console.log(UserID)
            if (UserID == null) {
                return;
            }
            this.info.IssueID = UserID;
            try {
              const response = await GetUserInfo(UserID);

              let data = response.data;
              this.info.ActShName = data.UserName;
              this.info.ActShAdd = data.Address;
              this.info.ActShTel = data.Tel;

              if (data.Shippersw) {
                this.showSH = true; 
                this.info.ShName = data.UserName;
                this.info.ShAdd = data.Address;
                this.info.ShTel = data.Tel;
              }

            } catch (e) {
              console.log(e);
            }
          },
          async loadPricingDetail() {
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2
            });


            try {
              const response = await GetPricing(this.info.SpaceID);

              this.info.ETA = response.data.ETA;
              this.info.ETD = response.data.ETD;
              this.info.PolName = response.data.PolName;
              this.info.PodName = response.data.PodName;
              this.info.PldName = response.data.PldName;
              this.info.VslName = response.data.VslName;
              this.info.Voy = response.data.Voy;
              this.info.PrCode = response.data.PrCode;
              this.info.PolPodTT = response.data.PolPodTT;
              this.info.GP20 = response.data.GP20;
              this.info.GP40 = response.data.GP40;
              this.info.HQ40 = response.data.HQ40;
              this.info.HQ45 = response.data.HQ45;
              
              this.info.GP20F = formatter.format(response.data.GP20);
              this.info.GP40F = formatter.format(response.data.GP40);
              this.info.HQ40F = formatter.format(response.data.HQ40);
              this.info.HQ45F = formatter.format(response.data.HQ45);
              this.calTotal();

              
            } catch(e) {
              console.log(e);
            }
          }
        }
    }
    
    function getPkgUnit() {
        return ['KG', 'LB'];
    }
</script>


<style scoped>
.register {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        padding: 20px;
        margin-top: 50px;
        margin-right: 70px;
    }

.textbox {

        width: 100%;
        border: 1px solid #CCCCCC;
}
.smaller {
    font-size: smaller
}


.btn {
    font-family: 'Century Gothic';
}
.register_btn {
    width: 100%;
}
</style>