import http from "./httpClient";

const END_POINT_SELECT_PORT = "/BookingApi/SelectPort";

const END_POINT_SELECT_CITY = "/BookingApi/SelectCity";

const END_POINT_SELECT_COUNTRY = "/BookingApi/SelectCountry";

const END_POINT_GET_ALL_PORT = "/BookingApi/GetAllPort";

const SEARCH_AGENT_FCL_PRICING = "/BookingApi/SearchAgentFCLPricing";

const SEARCH_AGENT_LCL_PRICING = "/BookingApi/SearchAgentLCLPricing";

const SEARCH_AGENT_AIR_PRICING = "/BookingApi/SearchAgentAirPricing";

const SEARCH_PRICING = "/BookingApi/SearchPricing";

const GET_PRICING = "/BookingApi/GetPricing";

const BOOKING_LOGIN = "/BookingApi/EFreightLogin";

const GET_USER_INFO = "/BookingApi/GetUserInfo";

const REGISTER_USER = "/BookingApi/RegisterUser";

const CREATE_BOOKING = "/BookingApi/CreateBooking";

const SEARCH_BOOKING = "/BookingApi/SearchBooking";

const CANCEL_BOOKING = "/BookingApi/CancelBooking";

const UPDATE_BOOKING = "/BookingApi/UpdateBooking";

const GET_BOOKING = "/BookingApi/GetBooking";

const SEND_INQUIRY = "/BookingApi/SendInquiry";

const GET_SERVICE_ROUTING = "/BookingApi/GetServiceRouting";

const GET_LOCAL_CHARGES = "/BookingApi/GetLocalCharges";

const CREATE_USER = "/BookingApi/CreateUser";

const CHANGE_PASSWORD = "/BookingApi/ChangePassword";

const GET_DISCOUNT = "/BookingApi/GetDiscount";

const IS_ALLOW_DISCOUNT = "/BookingApi/isShowDiscount";

const GENERATE_RATESHEET = "/BookingApi/GenerateRateSheet";

const SelectPortApi = (searchVal, type) => {
  const data = { searchVal, type };
  return http.post(END_POINT_SELECT_PORT, JSON.stringify(data));
};

const GetAllPortApi = () => {
  const data = {};
  return http.post(END_POINT_GET_ALL_PORT, JSON.stringify(data));
};

const SelectCityApi = (searchVal, country) => {
  const data = { searchVal, country };
  return http.post(END_POINT_SELECT_CITY, JSON.stringify(data));
};

const SelectCountryApi = (searchVal) => {
  const data = { searchVal };
  return http.post(END_POINT_SELECT_COUNTRY, JSON.stringify(data));
};

const SearchAgentFCLPricing = (params) => {
  const data = {Filter : params};
  return http.post(SEARCH_AGENT_FCL_PRICING, JSON.stringify(data));
};

const SearchAgentLCLPricing = (params) => {
  const data = {Filter : params};
  return http.post(SEARCH_AGENT_LCL_PRICING, JSON.stringify(data), {
    timeout: 45000,
  });
};

const SearchAgentAirPricing = (params) => {
  const data = {Filter : params};
  return http.post(SEARCH_AGENT_AIR_PRICING, JSON.stringify(data), {
    timeout: 45000,
  });
};

const SearchPricing = (params) => {
  const data = {Filter : params};
  return http.post(SEARCH_PRICING, JSON.stringify(data), {
    timeout: 45000,
  });
};

const SendInquiry = (params) => {
  const data = {Inquiry : params};
  return http.post(SEND_INQUIRY, JSON.stringify(data), {
    timeout: 20000,
  });
};

const GetPricing = (SpaceID) => {
  const data = {SpaceID : SpaceID};
  return http.post(GET_PRICING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const SystemLogin = (UserID, Password) => {
  const data = {UserID : UserID, Password: Password};
  return http.post(BOOKING_LOGIN, JSON.stringify(data), {
    timeout: 20000,
  });
};

const GetUserInfo = (UserID) => {
  const data = {UserID : UserID};
  return http.post(GET_USER_INFO, JSON.stringify(data), {
    timeout: 20000,
  });
};

const RegisterUser = (Form) => {
  const data = {Form : Form};
  return http.post(REGISTER_USER, JSON.stringify(data), {
    timeout: 20000,
  });
};

const CreateBooking = (Form) => {
  const data = {Form : Form};
  return http.post(CREATE_BOOKING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const GetBooking = (SONo, UserID) => {
  const data = {SONo : SONo, UserID: UserID};
  return http.post(GET_BOOKING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const CancelBooking = (SONo) => {
  const data = {SONo : SONo};
  return http.post(CANCEL_BOOKING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const UpdateBooking = (Form) => {
  const data = {Form : Form};
  return http.post(UPDATE_BOOKING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const SearchBooking = (Filter) => {
  const data = {Filter : Filter};
  return http.post(SEARCH_BOOKING, JSON.stringify(data), {
    timeout: 20000,
  });
};

const GetServiceRouting = (SpaceID) => {
  const data = {SpaceID : SpaceID};
  return http.post(GET_SERVICE_ROUTING, JSON.stringify(data), {
    timeout: 20000,
  });
}

const GetLocalCharges = (SpaceID) => {
  const data = {SpaceID : SpaceID};
  return http.post(GET_LOCAL_CHARGES, JSON.stringify(data), {
    timeout: 20000,
  });
}

const CreateUser = (UserID, email, password) => {
  const data = {UserID : UserID, email: email, password: password};
  return http.post(CREATE_USER, JSON.stringify(data), {
    timeout: 20000,
  });
}

const ChangePassword = (UserID, currentPassword, newPassword) => {
  const data = {UserID : UserID, currentPassword: currentPassword, newPassword: newPassword};
  return http.post(CHANGE_PASSWORD, JSON.stringify(data), {
    timeout: 20000,
  });
}

const IsAllowDiscount = (UserID) => {
  const data = {UserID: UserID};
  return http.post(IS_ALLOW_DISCOUNT, JSON.stringify(data), {
    timeout: 20000,
  });
}
const GetDiscount = (UserID, DiscountCode) => {
  const data = {UserID: UserID, DiscountCode : DiscountCode};
  return http.post(GET_DISCOUNT, JSON.stringify(data), {
    timeout: 20000,
  });
}
const GenerateRateSheet = (origins, destinations) => {
  const data = {origins: origins, destinations : destinations};
  return http.post(GENERATE_RATESHEET, JSON.stringify(data), {
    timeout: 20000,
  });
}

export { SelectPortApi, GetAllPortApi, SelectCityApi, SelectCountryApi, SearchPricing, SystemLogin, GetUserInfo, RegisterUser, GetPricing, 
  CreateBooking, SearchBooking, CancelBooking, UpdateBooking, GetBooking, SendInquiry, GetServiceRouting, GetLocalCharges, 
  CreateUser, ChangePassword, GetDiscount, IsAllowDiscount, SearchAgentFCLPricing, SearchAgentLCLPricing, SearchAgentAirPricing, GenerateRateSheet };
