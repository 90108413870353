<template>
    <div class="document">
        <main-menu></main-menu>
        
        <v-app id="inspire" style="background-color:#f3f3f3" >
          <v-card class="mb-12" flat>
            <v-tabs fixed-tabs v-model="bookingForm" color="black" background-color="#92D050">
              <v-tab>Info</v-tab>
              <v-tab>Booking Parties</v-tab>
              <v-tab>Containers</v-tab>
              <v-tab>Cargo</v-tab>
            </v-tabs>
            <v-tabs-items v-model="bookingForm">
              
              <v-tab-item class="mb-12" flat>
                <v-card class="mb-5 py-5" height="500">
                  <v-row class="pa-5">
                    <v-col>
                      Booking No. {{info.SONo}}
                    </v-col>
                  </v-row>
                <table style="width:100%">
                    <tr>
                      <td cols="2">
                        <v-card-subtitle class="py-0 detail-title">Carrier</v-card-subtitle>
                      </td>
                      <td cols="3">
                        <v-card-subtitle class="py-0 detail-title">ETD</v-card-subtitle>
                      </td>
                      <td cols="2">
                        <v-card-subtitle class="py-0 detail-title">TT</v-card-subtitle>
                      </td>
                      <td cols="3">
                        <v-card-subtitle class="py-0 detail-title">ETA</v-card-subtitle>
                      </td>
                      <td cols="2">
                        <v-card-subtitle class="py-0 detail-title">Vessel Voyage</v-card-subtitle>
                      </td>
                    </tr>
                    <tr no-gutters>
                      
                      <td cols="2" rowspan="2">
                        <v-img contain :aspect-ratio="1" width="150px" height="60px" :src="`${publicPath}images/pr/${this.info.PrCode}.png`">
                        </v-img>  
                      </td>
                      
                      <td cols="3">
                        <v-card-text class="py-0">{{this.info.Etd}}</v-card-text>
                      </td>

                      <td cols="2">
                        <v-card-text class="py-0">{{this.info.TT}} Days</v-card-text>
                      </td>
                      <td cols="3">
                        <v-card-text class="py-0">{{this.info.Eta}}</v-card-text>
                      </td>
                      <td cols="2">
                        <v-card-text class="py-0">{{this.info.Vsl2Name + " " + this.info.Voy2}}</v-card-text>
                      </td>
                    </tr>
                    <tr no-gutters>
                      <td cols="3">
                        <v-card-text class="py-1">{{this.info.PolName}}</v-card-text>
                      </td>
                      <td cols="2">&nbsp;</td>
                      <td>
                        <v-card-text class="py-1">{{this.info.PldName}}</v-card-text>
                      </td>
                      
                    </tr>
                  </table>
                </v-card>
              </v-tab-item>
              <v-tab-item >
                <v-card class="mb-12" flat>
                  <v-card class="party" >
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Booking Party</v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.ActShName" @input="textToUpper('ActShName', info)"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.ActShTel" @input="textToUpper('ActShTel', info)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Reference" hide-details="auto" v-model="info.AgtRefNo" @input="textToUpper('AgtRefNo', info)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-sh" v-show="showSH===true">
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Shipper
                        <v-btn icon color="pink" @click="showSH=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.ShName" @input="textToUpper('ShName', info)"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.ShTel" @input="textToUpper('ShTel', info)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.ShAdd" @input="textToUpper('ShAdd', info)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-cn" v-show="showCN===true">     
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Consignee
                        <v-btn icon color="pink" @click="showCN=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.CnName" @input="textToUpper('CnName', info)"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.CnTel" @input="textToUpper('CnTel', info)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.CnAdd" @input="textToUpper('CnAdd', info)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="party party-np" v-show="showNP===true">
                    <v-row class="mx-auto" no-gutters>
                      <v-card-title>Notify Party
                        <v-btn icon color="pink" @click="showNP=false">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col cols="8">
                        <v-text-field label="Name" :rules="emptyRules" hide-details="auto" v-model="info.NpName"  @input="textToUpper('NpName', info)"></v-text-field>  
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Phone" :rules="emptyRules" hide-details="auto" v-model="info.NpTel"  @input="textToUpper('NpTel', info)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto">
                      <v-col>
                        <v-text-field label="Address" :rules="emptyRules" hide-details="auto" v-model="info.NpAdd" @input="textToUpper('NpAdd', info)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row>
                    <v-col>
                      <v-card-text v-show="showSH===false">
                        <v-btn icon color="pink" @click="showSH=true" class="show-sh">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Shipper
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text v-show="showCN===false">
                        <v-btn icon color="pink" @click="showCN=true" class="show-cn">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Consignee
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text v-show="showNP===false">
                        <v-btn icon color="pink" @click="showNP=true" class="show-np">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>Notify Party
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
        
              <v-tab-item>
                <v-card flat class="mx-auto" >
                  <v-row class="px-5">
                    <v-col cols=2>
                      <input type="radio" v-model="markingType" value="single" @change="changeMarkingType('single')" checked /> One Description for all Containers 
                    </v-col>
                    <v-col cols=2>
                      <input type="radio" v-model="markingType" value="multi" @change="changeMarkingType('multi')" /> Multiple Description for Containers
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-simple-table class="mx-5 my-5"  fixed-header height="400px" >
                      <thead >
                        <tr>
                          <th class='px-1' v-show="isShowRowSelect" style="text-align:center; background-color:gray;color:white;">&nbsp;</th>
                          <th class='px-2' style="text-align:center; background-color:gray;color:white;">Container No </th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Seal No 1</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Seal No 2</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">MSO No</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Equipment</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Pkg</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Unit</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">Weight (KGS)</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">CBM</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">VGM (KGS)</th>
                          <th class='px-1' style="text-align:center; background-color:gray;color:white;">VGM Method</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="cntr in info.cntrs" :key="cntr.CntrID">
                          <td class="px-2" v-show="isShowRowSelect"><input type="radio" dense :checked="cntr.isSelected" name="cntrSelected" @click="selectCntr(cntr)" /> </td>
                          <td class="px-1"><v-text-field dense v-model="cntr.CntrNo" label="" @input="textToUpper('CntrNo', cntr)" ></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.SealNo1" label="" @input="textToUpper('SealNo1', cntr)"></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.SealNo2" label="" @input="textToUpper('SealNo2', cntr)"></v-text-field></td>
                          <td class="px-1"><v-text-field readonly dense v-model="cntr.MSONo" label=""></v-text-field></td>
                          <td class="px-1"><v-text-field readonly dense v-model="cntr.EqptName" ></v-text-field></td>
                          <td class="px-1"><v-text-field readonly dense v-model="cntr.Pkg" class="number"></v-text-field></td>
                          <td class="px-1"><v-text-field readonly dense v-model="cntr.PkgUnit" ></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.Wgt" label="" class="number"></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.Cbm" label="" class="number"></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.VGM" label="" class="number"></v-text-field></td>
                          <td class="px-1"><v-text-field dense v-model="cntr.VGMMethod" label=""></v-text-field></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-row>
                  <v-row class="pa-2">
                    <v-col>
                      <v-textarea outlined label="Marking" v-model="markings" @input="textToUpper('markings')"></v-textarea>
                    </v-col>
                    <v-col>
                      <v-textarea outlined label="Description" v-model="descriptions" @input="textToUpper('descriptions')" ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
                

              </v-tab-item>
        
              <v-tab-item>
                
                <v-card flat class="mx-auto" max-width="500px" min-height="500" >
                  <v-card-text>
                    Commodity
                  </v-card-text>
                  <v-row justify="center" v-for="cargo in info.cargos" :key="cargo.index">
                    <v-col cols=6>
                      <v-text-field v-model="cargo.commodity" :rules="emptyRules" label="Commodity" outlined></v-text-field>
                    </v-col>
                    <v-col cols=5>
                      <v-text-field v-model="cargo.hscode" label="HSCode" outlined></v-text-field>
                    </v-col>
                    
                    <v-col cols=1 >
                      
                      <v-btn icon @click="deleteCargo(cargo.index)">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn icon @click="addCargo()">
                        <v-icon color="pink">mdi-plus</v-icon>
                      </v-btn>
                      Add Cargo
                    </v-col>
                  </v-row>
                </v-card>
                
                <v-row justify="space-around"> 
                  <v-snackbar centered color="error" v-model="cargoSnackbar">
                      {{ this.cargoMessage }}
                      <v-btn text @click="cargoSnackbar = false" style="float:right" >
                          <v-icon small>mdi-close</v-icon>
                      </v-btn>
                  </v-snackbar>
                </v-row>
              </v-tab-item>
              
            </v-tabs-items>
            <v-row>
              <v-btn tile large color="#92D050" dark class="mx-auto mb-10 btn" @click="updateBooking">
                  <v-icon >mdi-floppy</v-icon> Save
              </v-btn>
            </v-row>
          </v-card>
        </v-app>
    </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from '../components/Menu';
    import { GetBooking, UpdateBooking } from "@/api/publicServices";

    Vue.component("main-menu", {MainMenu});
    export default {
        components: {MainMenu},
        mounted () {
            this.loadInfo(this.$route.query.SONo);
        },
        data: () => ({
          publicPath: (process.env.BASE_URL == "/")?"./":process.env.BASE_URL,
          emptyRules: [
            value => !!value || 'Required.'
          ],
          emailRules: [
            value => !!value || 'Required.',
            value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
            },
          ],
          pkgRules: [
            value => {
              const pattern = /^\d+(\.\d+)*$/
              return pattern.test(value) || 'Must be a number'
            },
            value => value != 0 || 'Weight cannot be 0.'
          ],
          vgm_date_menu: false,
          showSH: false,
          showCN: false,
          showNP: false,
          showq20: false,
          showq40: false,
          showq40h: false,
          showq45h: false,
          bookingForm:null,
          pkgUnit: getPkgUnit(),
          info : {
            ActShName :"",
            ActShTel :"",
            ActShAdd :"",
            ShName :"",
            ShTel :"",
            ShAdd :"",
            CnName :"",
            CnTel :"",
            CnAdd :"",
            NpName :"",
            NpTel :"",
            NpAdd :"",
            PrCode:"APLU",
            ETA:"",
            ETD:"",
            VslName:"",
            Voy:"",
            PldName:"",
            PodName:"",
            PolName:"",
            PolPodTT:"",
            q20qty: 0,
            q20pkg : 0,
            q20pkgunit : 'KG',
            q40qty: 0,
            q40pkg : 0,
            q40pkgunit : 'KG',
            q40hqqty: 0,
            q40hqpkg : 0,
            q40hqpkgunit : 'KG',
            q45hqqty: 0,
            q45hqpkg : 0,
            q45hqpkgunit : 'KG',
            SeaType: "",
            cargos: [{ index: Date.now(), commodity:"", hscode:""}]
          },
          cargoSnackbar:false,
          cargoMessage:"",
          isShowRowSelect: false,
          markings:"",
          descriptions:"",
          markingType:"single",
          lastSelectedCntr:""
        }),
        methods : {
          textToUpper(field, obj) {

            if (obj != undefined) {
              obj[field] = obj[field].toUpperCase()
            } else {
              this[field] = this[field].toUpperCase()
            }
          },
          saveCurrent() {
            if (this.lastSelectedCntr != "") {
              for (let index in this.info.cntrs) {
                if ( this.info.cntrs[index].CntrID == this.lastSelectedCntr) {
                  this.info.cntrs[index].markings = this.markings;
                  this.info.cntrs[index].descriptions = this.descriptions;
                }
              }
            }
          },
          selectCntr(cntr) {
            this.saveCurrent();
            this.lastSelectedCntr = cntr.CntrID;
            this.markings = cntr.markings;
            this.descriptions = cntr.descriptions;
          },
          changeMarkingType(type) {
            this.saveCurrent();
            this.markingType = type;
            
            let groupMarkings = "";
            let groupDescriptions = "";
            for (let index in this.info.cntrs) {
              let cntr = this.info.cntrs[index];
              if (cntr.markings.length > 0) {
                groupMarkings += cntr.markings + "\n";
              }
              if (cntr.descriptions.length > 0) {
                groupDescriptions += cntr.descriptions + "\n";
              }
              cntr.markings = "";
              cntr.descriptions = "";
              cntr.isSelected = false;
            }
            if (type == 'single') {
                

              if (this.info.cntrs.length > 0) {
                groupMarkings = groupMarkings.slice(0, -1);
                groupDescriptions = groupDescriptions.slice(0, -1);
              }
              
              this.markings = groupMarkings;
              this.descriptions = groupDescriptions;

              this.info.cntrs[0].markings = groupMarkings;
              this.info.cntrs[0].descriptions = groupDescriptions;

              this.isShowRowSelect = false;
              this.lastSelectedCntr = "";
            } else {
              this.isShowRowSelect = true;
              this.info.cntrs[0].isSelected = true;
              this.info.cntrs[0].markings = this.markings;
              this.info.cntrs[0].descriptions = this.descriptions;
            }
          }, 
          calTotal() {
            
              const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2
              });
              let total20 = this.info.GP20 * this.info.q20qty;
              let total40 = this.info.GP40 * this.info.q40qty;
              let total40hq = this.info.HQ40 * this.info.q40hqqty;
              let total45hq = this.info.HQ45 * this.info.q45hqqty;
              
              this.info.GP20TF = formatter.format(total20);
              this.info.GP40TF = formatter.format(total40);
              this.info.HQ40TF = formatter.format(total40hq);
              this.info.HQ45TF = formatter.format(total45hq);

              this.info.Total = total20 + total40 + total40hq + total45hq;
              this.info.TotalF = formatter.format(this.info.Total);

          },
          async updateBooking() {
            try {
              
              this.saveCurrent();
              for (let index in this.info.cargos) {
                this.info.cargos[index].index = index;
              }

              const response = await UpdateBooking(this.info);

              if (response.data.isSuccess) {
                this.$router.push({name:'booking'});
              } else {
                return;
              }
            } catch (e) {
              console.log(e);
              return;
            }
          },
          addCargo() {
            this.info.cargos.push( {index: Date.now(), commodity:"", hscode: ""});
          },
          deleteCargo(cargoIndex) {
            for (var index in this.info.cargos) {
              var cargo = this.info.cargos[index];
              if (cargo.index === cargoIndex) { 
                this.info.cargos.splice(index, 1);
                break;
              }
            }
          }, 
          getQty(qty) {
            if (this.$route.params[qty]) {
              return this.$route.params[qty];
            } else {
              return 0;
            }
          },
          async loadInfo(SONo) {
            
            let UserID = localStorage.getItem('userid');
            try {
              const response = await GetBooking(SONo, UserID);
              this.markingType = "undefined";
              this.info = response.data;
              this.info.cargos = [];
              for (var index in this.info.OnlineBkgComdys) {
                var OnlineBkgComdy = this.info.OnlineBkgComdys[index];
                this.info.cargos.push({ index: Date.now(), commodity:OnlineBkgComdy.ComdyName, hscode:OnlineBkgComdy.ComdyCode});
              }

              this.info.q20qty = 0;
              this.info.q40qty = 0;
              this.info.q40hqqty = 0;
              this.info.q45hqqty = 0;
              
              this.info.q20pkg = 0;
              this.info.q40pkg = 0;
              this.info.q40hqpkg = 0;
              this.info.q45hqpkg = 0;

              this.info.q20pkgunit = "KG";
              this.info.q40pkgunit = "KG";
              this.info.q40hqpkgunit = "KG";
              this.info.q45hqpkgunit = "KG";
              let ETD = new Date(this.info.Etd);
              let ETA = new Date(this.info.Eta);
              console.log(ETD)
              console.log(ETA)
              this.info.TT = Math.ceil((ETA - ETD) / (1000 * 60 * 60 * 24)); 
              this.info.Etd = this.info.Etd.substring(0,10);
              this.info.Eta = this.info.Eta.substring(0,10);
              if (this.info.ShName != "") {
                this.showSH = true;
              }
              
              if (this.info.CnName != "") {
                this.showCN = true;
              }
              
              if (this.info.NpName != "") {
                this.showNP = true;
              }
              this.info.cntrs = [];
              for (var index2 in this.info.OnlineBkgCntrs) {
                  let cntr = this.info.OnlineBkgCntrs[index2];
                  cntr.isSelected = (index2 == 0) ;
                  if (this.lastSelectedCntr == "") {
                    this.lastSelectedCntr = cntr.CntrID;
                  }
                  if (cntr.EqptCode == 'D20S') {
                    this.info.q20qty +=1;
                    this.info.q20pkg += cntr.Pkg;
                    this.info.q20pkgunit = cntr.PkgUnit;
                    cntr.EqptName = "20' GP";
                  } else if (cntr.EqptCode == 'D40S') {
                    this.info.q40qty +=1;
                    this.info.q40pkg += cntr.Pkg;
                    this.info.q40pkgunit = cntr.PkgUnit;
                    cntr.EqptName = "40' GP";
                  } else if (cntr.EqptCode == 'D40H') {
                    this.info.q40hqqty +=1;
                    this.info.q40hqpkg += cntr.Pkg;
                    this.info.q40hqpkgunit = cntr.PkgUnit;
                    cntr.EqptName = "40' HQ";
                  } else if (cntr.EqptCode == 'D45H') {
                    this.info.q45hqqty +=1;
                    this.info.q45hqpkg += cntr.Pkg;
                    this.info.q45hqpkgunit = cntr.PkgUnit;
                    cntr.EqptName = "45' HQ";
                  }
                  cntr.markings ="";
                  cntr.descriptions ="";
                  for (var index3 in cntr.OnlineBkgMarks) {
                    cntr.markings += cntr.OnlineBkgMarks[index3].Marking + "\n";
                    cntr.descriptions += cntr.OnlineBkgMarks[index3].Description + "\n";
                  }
                  
                  if (cntr.markings != "") {
                    cntr.markings = cntr.markings.slice(0, -1);
                  }
                  if (cntr.descriptions != "") {
                    cntr.descriptions = cntr.descriptions.slice(0, -1);
                  }
                  if (cntr.OnlineBkgMarks.length > 0) {
                    if (this.markingType == "undefined") {
                      this.markingType = "single";
                      this.markings = cntr.markings;
                      this.descriptions = cntr.descriptions;
                    } else if (this.markingType == "single") {
                      this.markingType = "multi";
                      this.isShowRowSelect = true;
                      this.info.cntrs[0].isSelected = true;
                      
                    }
                  }
                  this.info.cntrs.push(cntr);
              }
              
              if (this.markingType == "undefined") {
                this.markingType = "single";
              }

              console.log("markingType : " + this.markingType)
            } catch (e) {
              console.log(e);
            }
          }
        }
    }

    function getPkgUnit() {
        return ['KG', 'LB'];
    }
</script>


<style scoped>
.party {
  background-color:white;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}
.btn {
    font-family: 'Century Gothic';
}

.number >>> input {
  text-align: right
}

</style>